// import { getCookieJSON } from '../../libs/cookies'
import { getCookie, removeCookie, setCookie } from "../../libs/cookies";

import React from "react";
import Sidebar from "react-sidebar";
import SidebarContents from "./SidebarContents";
import Titlebar from "../Titlebar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { icon } from "../../libs/icon";
import { toggleSidebar } from "../../redux/actions/action_sidebar";
import UpdatePaymentMethod from "../UpdatePaymentMethod";
import { hasValueInArray } from "../../libs/validator";
import { AddCreditAlert } from "./AddCreditAlert";
import _ from "lodash";

const cookie_name_skipWCP = "__skipWCP";

class _Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      docked: true,
    };
    let skipWCP = getCookie(cookie_name_skipWCP) || {};
    setCookie(cookie_name_skipWCP, skipWCP);
  }

  componentDidMount() {
    this.setState({ docked: window.innerWidth >= 992 });
    window.onresize = () => {
      this.setState({ docked: window.innerWidth >= 992 });
    };
  }

  componentDidUpdate() {
    let {
      store_business: { current },
    } = this.props;
    this.getSkip(current.business_code);
  }

  getSkip(business_code) {
    let skipWCP = getCookie(cookie_name_skipWCP);
    if (skipWCP[business_code]) return true;

    let skip_old = getCookie(`${cookie_name_skipWCP}_${business_code}`);
    if (skip_old) {
      setCookie(cookie_name_skipWCP, { business_code: skip_old });
      removeCookie(`${cookie_name_skipWCP}_${business_code}`);
      return true;
    }

    return false;
  }

  render() {
    let {
      store_business: {
        current,
        current: { permission },
      },
    } = this.props;
    let {
      store_language: { dictionary },
    } = this.props;
    let {
      store_packages: { packages, isProductPointsAddon, packages_free, subscription_items }
    } = this.props;
    let {
      store_usage: { usage_limit },
    } = this.props;
    let { store_sidebar, toggleSidebar } = this.props;
    let {
      store_newsfeed: { nextfeed },
    } = this.props;

    let lockMenu =
      packages &&
      packages.package_data &&
      !hasValueInArray(packages_free, packages.package_data.package_id)
        ? packages.subscription_status === "hold"
        : usage_limit.isOverUsage;

    let sidebarStyle = {
      content: { overflowY: "auto" },
      overlay: { zIndex: 1029 },
      sidebar: { width: "90%", maxWidth: 280, zIndex: 1030, boxShadow: "none" },
    };

    let showCustomForm = Boolean(
      _.find(
        hasValueInArray(packages_free, packages.package_data.package_id)
          ? packages && packages.package_data && packages.package_data.items          
          : subscription_items,
        { filter_term: 'ps-custom-form' }
      )
    );

    let menu_list = {
      sidebarOpen: false,
      docked: true,
      menu: [
        {
          name: "qrcode",
          title: "Scan QR code",
          icon: icon.qrcode,
          href: "/qr",
          show: false,
        },
        {
          name: "welcome",
          title: dictionary.home_page,
          icon: "fas fa-home",
          href: "/",
          show: true,
          serach: true,
          // advanced: true,
        },
        {
          name: "newsfeed",
          title: dictionary.activities,
          icon: "fas fa-rss fa-flip-horizontal",
          href: "/newsfeed",
          show: true,
          serach: true,
          badge: nextfeed.length,
        },
        {
          name: "member",
          title: dictionary.report_members,
          href: "",
          show: true,
          serach: true,
          submenu: [
            {
              name: "member",
              title: dictionary.members_infomation,
              icon: icon.user,
              href: "/member",
              show: true,
            },
            {
              name: "setting-member-card",
              title: dictionary.member_card,
              icon: "fas fa-address-card",
              href: "/memberCard",
              show: true,
            },
            {
              name: "membercard-batchjob",
              title: dictionary.batchJobsMemberCard,
              icon: "fas fa-cubes",
              href: "/jobsMemberCard",
              show: true,
              disable: !current.verified,
              lock: lockMenu,
            },
          ],
        },
        {
          name: "points",
          title: dictionary.points,
          href: "",
          show: true,
          serach: true,
          submenu: [
            {
              name: "points-transactions",
              title: dictionary.points_transactions,
              icon: icon.history,
              href: "/transactions",
              show: true,
            },
            {
              name: "points-send",
              "data-tut": "sidebar_send",
              title: dictionary.points_send_points,
              icon: icon.send_points,
              href: "/send",
              show: true,
              lock: lockMenu,
            },
            {
              name: "points-pull",
              title: dictionary.points_pull_points,
              icon: icon.pull_points,
              href: "/pull",
              show: true,
              lock: lockMenu,
            },
            {
              name: "points-request",
              title: dictionary.collect_point,
              icon: icon.request_points,
              href: "/request",
              show:
                packages.package_data &&
                packages.package_data.package_id === "ps-shappy"
                  ? false
                  : true,
              lock: lockMenu,
            },
            {
              name: "points-batchjob",
              title: dictionary.batchJobs,
              icon: "fas fa-cubes",
              href: "/jobs",
              show: true,
              disable: !current.verified,
              lock: lockMenu,
            },
          ],
        },
        {
          name: "ecoupon",
          title: `${dictionary.ecoupon} / ${dictionary.rewards}`,
          href: "",
          show: true,
          serach: true,
          submenu: [
            {
              name: "setting-group-reward",
              title: dictionary.setting_ecard_group_reward,
              label:
                permission &&
                (permission["setting-rewards"].permission_view ||
                  permission["giveaway-ecoupon"].permission_view),
              show: true,
              href: "",
            },
            {
              name: "setting-rewards",
              "data-tut": "sidebar_rewards",
              title: dictionary.setting_redeem_rewards,
              icon: icon.reward,
              href: "/rewards",
              show: true,
            },
            {
              name: "giveaway-ecoupon",
              title: dictionary.giveaway_coupon,
              icon: "fas fa-gifts",
              href: "/giveawayCoupon",
              show: true,
            },
            {
              name: "setting-group-coupon",
              title: dictionary.setting_ecard_group_coupon,
              label: permission &&
              (permission["setting-ecoupon"].permission_view ||
                permission["giftvoucher-ecoupon"].permission_view ||
                permission["welcome-ecoupon"].permission_view),
              show: true,
              href: "",
            },
            {
              name: "setting-ecoupon",
              title: dictionary.attached_coupon,
              icon: "fas fa-receipt",
              href: "/attachedCoupon",
              show: true,
              lock: lockMenu,
              disable: !current.verified,
            },
            {
              name: "setting-ecoupon",
              title: dictionary.coupon_promotion,
              icon: "fas fa-candy-cane",
              href: "/ecoupon",
              show: true,
              lock: lockMenu,
              disable: !current.verified,
            },
            {
              name: "giftvoucher-ecoupon",
              title: dictionary.gift_voucher,
              icon: "fas fa-money-bill",
              href: "/giftvoucher",
              show: true,
              lock: lockMenu,
              disable: !current.verified,
            },
            {
              name: "welcome-ecoupon",
              title: dictionary.coupon_welcome,
              icon: "fas fa-handshake",
              href: "/welcomeecoupon",
              show: true,
            },
            {
              name: "ecoupon-activate",
              "data-tut": "sidebar_ecouponactivate",
              title: dictionary.points_activate_ecoupon,
              href: "/ecouponactivate",
              show: true,
              lock: lockMenu,
              disable: !current.verified,
            },
          ],
        },
        {
          name: "automations",
          title: dictionary.automation,
          href: "",
          show:
            packages.package_data &&
            packages.package_data.package_id === "ps-shappy"
              ? false
              : true,
          submenu: [
            {
              name: "automation-member-card",
              title: dictionary.member_card,
              icon: "fas fa-address-card",
              href: "/automationMemberCard",
              show: true,
              disable: !current.verified,
              lock: lockMenu,
            },
            {
              name: "automation-ecoupon",
              title: dictionary.ecoupon,
              icon: "fas fa-money-check",
              href: "/automationEcard",
              show: true,
              disable: !current.verified,
              lock: lockMenu,
            },
          ],
        },
        {
          name: "reports",
          title: dictionary.reports,
          href: "",
          show: true,
          submenu: [
            {
              name: "report-dashboard",
              title: dictionary.dashboard_report,
              icon: "fas fa-chart-area",
              href: "/dashboardReport",
              show: true,
            },
            {
              name: "report-points-flow",
              title: dictionary.points_flow,
              icon: "fas fa-exchange-alt",
              href: "/pointsFlowReport",
              show: true,
            },
            {
              name: "report-RFM-analysis",
              title: dictionary.RFM_analysis,
              icon: "fas fa-trophy",
              href: "/RFMReport",
              show: true,
            },
            {
              name: "report-birthday",
              title: dictionary.report_birthday,
              icon: icon.birthday,
              href: "/birthdayReport",
              show: true,
            },
            {
              name: "report-demographic",
              title: dictionary.report_demographic,
              icon: icon.demographic,
              href: "/demographicReport",
              show: true,
            },
            {
              name: "report-frequency",
              title: dictionary.report_frequency,
              icon: "fas fa-chart-bar",
              href: "/frequencyReport",
              lock: lockMenu,
              disable: !current.verified,
              show: true,
            },
            {
              name: "report-recency",
              title: dictionary.recency_report,
              icon: "fas fa-chart-line",
              href: "/recencyReport",
              show: true,
            },
            {
              name: "report-rewards",
              title: dictionary.rewards,
              icon: icon.reward,
              href: "/rewardsReport",
              show: true,
            },
            {
              name: "report-ecoupon",
              title: dictionary.ecoupon,
              icon: "fas fa-money-check",
              href: "/ecouponReport",
              show: true,
            },
            {
              name: "report-product",
              title: dictionary.product,
              icon: "fas fa-file-invoice-dollar",
              href: "/productReport",
              show: true,
              lock: lockMenu,
            },
            {
              name: "report-admin",
              title: dictionary.admin,
              icon: "fas fa-user-shield",
              href: "/adminReport",
              show: true,
            },
            {
              name: "report-review",
              title: `${dictionary.report_satisfaction} & ${dictionary.report_review}`,
              icon: "fas fa-star",
              href: "/reviewsReport",
              show: true,
            },
            {
              name: "report-friend-get-friends",
              title: dictionary.friend_get_friends,
              icon: "fas fa-user-plus",
              href: "/getFriendsReport",
              disable: !current.verified,
              show: true,
            },
            {
              name: "report-transaction",
              title: dictionary.report_transactions,
              icon: icon.transaction,
              href: "/transactionReport",
              show: true,
            },
            {
              name: "report-transaction-admin",
              title: dictionary.report_admin,
              icon: "fas fa-user-tie",
              href: "/transactionReportAdmin",
              lock: lockMenu,
              disable: !current.verified,
              show: true,
            },
            {
              name: "report-transaction-sent",
              title: dictionary.transaction_sent,
              icon: icon.history,
              href: "/sentReport",
              show: true,
            },
            {
              name: "report-transaction-activated",
              title: dictionary.transaction_activated,
              icon: "fas fa-clipboard-check",
              href: "/activatedReport",
              show: true,
            },
            {
              name: "setting-transaction-membercard",
              title: dictionary.transaction_member_card,
              icon: "fas fa-id-card",
              href: "/transactionMembercard",
              show: true,
            },
          ],
        },
        {
          name: "setting",
          title: dictionary.settings,
          href: "",
          show: true,
          submenu: [
            {
              name: "setting-business",
              "data-tut": "sidebar_settings",
              title: dictionary.business_infomation,
              icon: icon.settings,
              href: "/settings",
              show: true,
            },
            {
              name: "setting-poster",
              "data-tut": "sidebar_poster",
              title: dictionary.business_poster,
              icon: "fas fa-clipboard",
              href: "/poster",
              show: true,
            },
            {
              name: "setting-business",
              title: dictionary.business_contact,
              icon: "fas fa-phone",
              href: "/contact",
              show: true,
            },
            {
              name: "setting-verify",
              "data-tut": "sidebar_verify",
              title: dictionary.verify_business,
              icon: "fas fa-check-circle",
              href: "/verify",
              show: true,
            },
            {
              name: "setting-terms",
              title: dictionary.setting_terms_conditions,
              icon: icon.terms,
              href: "/terms",
              show: true,
            },
            {
              name: "setting-subscribe",
              title: dictionary.setting_subscribe,
              icon: "fas fa-envelope-open",
              href: "/subscribe",
              show: true,
            },
            {
              name: "setting-points",
              title: dictionary.setting_points,
              icon: "fas fa-coins",
              href: "/settingsPoints",
              show: true,
            },
            {
              name: "setting-coupons",
              title: dictionary.setting_coupon,
              icon: "fas fa-ticket-alt",
              href: "/settingsCoupons",
              show: true,
            },
            {
              name: "setting-tags",
              title: dictionary.label,
              icon: "fas fa-tag",
              href: "/label",
              show: true,
            },
            {
              name: "setting-product",
              title: dictionary.products_management,
              icon: "fas fa-boxes",
              href: "/product",
              show: true,
              lock: lockMenu,
            },
            {
              name: "setting-game",
              title: dictionary.game,
              icon: "fas fa-gamepad",
              href: "/game",
              show:
                packages.package_data &&
                packages.package_data.package_id === "ps-shappy"
                  ? false
                  : true,
              lock: lockMenu,
              disable: !current.verified,
            },
            {
              name: "setting-additional-register",
              title: dictionary.setting_additional_register,
              icon: "fas fa-edit",
              href: "/additionalRegister",
              show: showCustomForm,
            },
            {
              name: "setting-friend-get-friends",
              title: dictionary.friend_get_friends,
              icon: "fas fa-people-arrows",
              href: "/settingFriendGetFriends",
              show: true,
              disable: !current.verified,
            },
            {
              name: "setting-credential",
              title: dictionary.setting_credential,
              icon: "fas fa-unlock-alt",
              href: "/credential",
              show: true,
              disable: !current.verified,
              lock: lockMenu,
            },
            {
              name: "setting-line-liff",
              title: dictionary.setting_line_liff,
              icon: "fab fa-line",
              href: "/lineLiff",
              show:
                packages.package_data &&
                packages.package_data.package_id === "ps-shappy"
                  ? false
                  : true,
              disable: !current.verified,
            },
            {
              name: "setting-admin",
              title: dictionary.setting_admin,
              icon: icon.admin,
              href: "/admin",
              show: true,
            },
            {
              name: "setting-security",
              title: dictionary.security,
              icon: "fas fa-shield-alt",
              href: "/security",
              show: true,
            },
          ],
        },
        {
          name: "addons",
          title: dictionary.addons,
          href: "",
          show: true,
          submenu: [
            {
              name: "broadcast-promotion",
              title: dictionary.broadcast,
              icon: "far fa-comment-dots",
              href: "/broadcastPromotion",
              show: true,
              disable: !current.verified,
              lock: lockMenu,
            },
          ],
        },
      ],
    };

    return (
      <Sidebar
        styles={sidebarStyle}
        onSetOpen={toggleSidebar}
        docked={this.state.docked}
        open={store_sidebar.toggle}
        contentClassName="Main-Content"
        sidebar={<SidebarContents {...menu_list} {...this.props} />}
      >
        <AddCreditAlert />
        <Titlebar {...menu_list} />
        <div className="Sub_main-Content">{this.props.children}</div>
        <UpdatePaymentMethod />
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store_sidebar: state.sidebar,
    store_language: state.language,
    store_business: state.business,
    store_packages: state.packages,
    store_usage: state.usage,
    store_newsfeed: state.newsfeed,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({ toggleSidebar }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(_Sidebar);
