import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessPackageSSE, getBusinessPackage } from "../../redux/actions/action_package";
import { Title2, Title3, Title4 } from "../Font";
import { DangerDot } from "./BusinessPackage.style";
import { TransparentBtn, UpgradeBtn } from "../../components/Button";
import ModalPackage from "./BusinessPackageModal";
import ModalSubscription from "./BusinessSubscriptionModal";
import ModalSubscriptionNewPackage from "./BusinessSubscriptionNewPackageModal";
import Loading from "../Loading";
import { hasValueInArray } from "../../libs/validator";
import { ERROR } from "../../libs/utils";

const permission_key = "setting-packages";

export default function BusinessPackage() {
  const [isOpenPackage, setIsOpenPackage] = useState(false);
  const [isOpenSubscription, setIsOpenSubscription] = useState(false);
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  const dispatch = useDispatch();
  const { dictionary } = useSelector((state) => state.language);
  const business_code = useSelector((state) => state.business.current.business_code);
  const { subscription } = useSelector((state) => state.packages);
  const { isNewPackage } = subscription;

  const pollingDelayRef = useRef(2000);
  let pollingTimeout;

  const getPackageBusiness = (business_code, load = false) => {
    if (load) setIsLoadingLocal(true);
    const cleanup = dispatch(
      getBusinessPackage({ business_code, load }, (err, data) => {
        // Call getBusinessPackage action
        if (err) {
          ERROR("Package fetch error:", err);
        } else {
          setIsLoadingLocal(false);
        }
      })
    );

    return cleanup;
  };

  const getPackageBusinessSSE = (business_code, load = false, callback) => {
    if (load) setIsLoadingLocal(true);

    const cleanup = dispatch(
      getBusinessPackageSSE({ business_code, load }, (err, data) => {
        if (err) {
          if (callback) callback(err);
        } else {
          setIsLoadingLocal(false);
          stopPolling();
          if (callback) callback(null, data);
        }
      })
    );

    return cleanup;
  };

  const startPolling = () => {
    stopPolling();

    pollingTimeout = setTimeout(() => {
      getPackageBusiness(business_code, false);
      pollingDelayRef.current = 60000;
      startPolling();
    }, pollingDelayRef.current);
  };

  const stopPolling = () => {
    if (pollingTimeout) {
      clearTimeout(pollingTimeout);
      pollingTimeout = null;
    }
  };

  useEffect(() => {
    let cleanup;
    let sseTimeout;

    const setupSSE = () => {
      if (cleanup) cleanup();
      stopPolling();

      sseTimeout = setTimeout(() => {
        ERROR("SSE connection timed out. back to polling.");
        if (cleanup) cleanup();
        pollingDelayRef.current = 2000;
        startPolling();
      }, 4000);

      cleanup = getPackageBusinessSSE(business_code, true, (err) => {
        clearTimeout(sseTimeout);
        if (err) {
          ERROR("SSE error. Falling back to polling:", err);
          pollingDelayRef.current = 2000;
          startPolling();
        }
      });
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setupSSE();
      } else {
        if (cleanup) cleanup();
        pollingDelayRef.current = 2000;
        startPolling();
      }
    };

    setupSSE();

    window.addEventListener("online", setupSSE);
    window.addEventListener("offline", stopPolling);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      if (cleanup) cleanup();
      clearTimeout(sseTimeout);
      stopPolling();

      window.removeEventListener("online", setupSSE);
      window.removeEventListener("offline", stopPolling);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [business_code]);

  const toggleModalPackagePlan = () => {
    setIsOpenPackage(!isOpenPackage);
  };

  const toggleModalSubscription = () => {
    setIsOpenSubscription(!isOpenSubscription);
  };

  const { packages, packages_list, isLoading, packages_free } = useSelector(
    (state) => state.packages
  );
  const {
    current: { permission }
  } = useSelector((state) => state.business);
  const { usage_record } = useSelector((state) => state.usage);

  const packageItems = (packages && packages.package_data) || {};
  const this_permission = (permission && permission[permission_key]) || {};
  const permiss_packages = (permission && permission["setting-packages"]) || {};

  return (
    <>
      <div className="border-top border-bottom" style={{ padding: "0.5rem 1rem" }}>
        <div className="d-flex align-items-center justify-content-between" style={{ minHeight: 50 }}>
          {isLoading || isLoadingLocal ? (
            <LoadingBox />
          ) : (
            <>
              <div>
                <Title2 bold>{`${dictionary[packageItems.title_dictionary]}`}</Title2>
                <Title4>{dictionary.current_usage_package}</Title4>
              </div>
              {Boolean(this_permission?.permission_view) && Boolean(packages.subscription_package) ? (
                <TransparentBtn
                  onClick={
                    Boolean(packages.subscription_package)
                      ? toggleModalSubscription
                      : toggleModalPackagePlan
                  }
                >
                  <Title3 bold link>
                    {dictionary.show_detail}
                    {((usage_record.credit_remaining_percent < 20 && !isNewPackage) ||
                      (isNewPackage &&
                        (usage_record.isTxCloseToLimit || usage_record.isCreditCloseToLimit))) && (
                      <DangerDot />
                    )}
                  </Title3>
                </TransparentBtn>
              ) : Boolean(permiss_packages?.permission_view) ? (
                <UpgradeBtn
                  style={{ marginRight: "14px" }}
                  onClick={
                    Boolean(packages.subscription_package)
                      ? toggleModalSubscription
                      : toggleModalPackagePlan
                  }
                >
                  {dictionary.upgrade}
                </UpgradeBtn>
              ) : null}
            </>
          )}
        </div>
      </div>
      {isOpenPackage && packages_list.length && (
        <ModalPackage
          isOpen={isOpenPackage}
          toggle={toggleModalPackagePlan}
          toggleSubscription={toggleModalSubscription}
          packageItems={packages_list.filter((v) => v.show)}
        />
      )}
      {isOpenSubscription &&
        !isNewPackage &&
        packages &&
        !hasValueInArray(packages_free, packages.subscription_package) && (
          <ModalSubscription
            packageItems={packageItems}
            isOpen={isOpenSubscription}
            toggle={toggleModalSubscription}
            togglePackage={toggleModalPackagePlan}
          />
        )}
      {isOpenSubscription &&
        isNewPackage &&
        packages &&
        !hasValueInArray(packages_free, packages.subscription_package) && (
          <ModalSubscriptionNewPackage
            packageItems={packageItems}
            isOpen={isOpenSubscription}
            toggle={toggleModalSubscription}
            togglePackage={toggleModalPackagePlan}
          />
        )}
    </>
  );
}

const LoadingBox = () => {
  return (
    <div className="w-100 d-flex align-items-center justify-content-center">
      <Loading size="2rem" margin="0" />
    </div>
  );
};
