import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { Card, CardHeader, CardBody, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import {
	pointsValidator,
	pointsDecimalValidator,
	receiverValidator,
	amountValidator,
	verifyDateOfBirth,
	verifyEmail,
	verifyAge,
	mobileValidator
} from "../../libs/validator";
import { Title1, Title2, Title3 } from "../../components/Font";
import { getcsvTitle } from "./ImportColumn";
import { createCronjob } from "../../redux/actions/action_jobs";
import PreDataTable from "./PreDataTable";
import _ from "lodash";
import { jsonToCsv } from "../../libs/csv";
import { toCurrency } from "../../libs/currency";

export default function PreDataContainer({ type, message, customRoute }) {
	const dispatch = useDispatch();
	const history = useHistory();

	const store_jobs = useSelector((state) => state.jobs);
	const store_business = useSelector((state) => state.business);
	const { locale, dictionary} = useSelector((state) => state.language);
	const { form_setting } = useSelector((state) => state.custom_form);

	const [jobs, setJobs] = useState({});
	const [modalIncorrectData, setModalIncorrectData] = useState(false);
	const [downloadAlready, setDownloadAlready] = useState(false);

	const business = _.get(store_business, ["current"]);
	const prepareJobs = store_jobs.prepareJobs;
	const importType = store_jobs.type;
	const memberCardInfoList = store_business.member_card_info;

	const submitJobsData = () => {
		if (jobs.incorrect.length > 0) {
			setModalIncorrectData(true);
			if (downloadAlready) {
				confirmSubmitData();
			} 
		}else{
			confirmSubmitData();
		} 
	};

	const toggle = () => {
		setModalIncorrectData(!modalIncorrectData);
	};

	const confirmSubmitData = () => {
		let initial_point = false;
		let pathname = window.location.pathname;

		if (pathname === "/jobsMemberCard/createPoint") {
			customRoute = "/jobsMemberCard/point";
			initial_point = true;
		} else if (pathname === "/jobsMemberCard/create") {
			customRoute = "/jobsMemberCard";
		}
		dispatch(
			createCronjob(
				business.business_code,
				{
					jobs: importType === "product" ? combineData(jobs.correct, type) : jobs.correct,
					message: message.text,
					type: type,
					initial_point: initial_point,
				},
				(id) => {
					history.push(`${customRoute ? customRoute : "/jobs"}/${id}`);
				}
			)
		);
	};

	const exportCSV = (incorrect_data) => {
		setDownloadAlready(true);
		setModalIncorrectData(true);
		let customForm = business && business.custom_form && customRoute === "/jobsMemberCard" ? form_setting : [];
		let title = getcsvTitle(importType, type, customRoute, customForm, locale);
		let custom_form_data = business && business.custom_form && customRoute === "/jobsMemberCard" ? form_setting.map((form) => { return `custom_form_${form.id}` }) : [];
		let incorrect_csv = [];
		let header = "\ufeff";
		title.forEach((item, index) => {
			header += item;
			if (index < title.length - 1) header += ",";
		});
		incorrect_csv.push(header);
		incorrect_data.forEach((data) => {
			if (customRoute === "/jobsMemberCard") {
				let form_data = '';
				if (custom_form_data.length > 0) {
					custom_form_data.forEach((form, index) => {
						form_data += `,${data[form]}`;
					});
				}
				if (type === "point") {
					if (importType === "product") {
						incorrect_csv.push(
							`\n${data.receiver},${data.amount},${data.sku},${data.receipt_no},${data.remark},${data.first_name},${data.last_name},${data.gender},${data.date_of_birth},${data.email}${form_data}`
						);
					} else if (importType === "amount") {
						incorrect_csv.push(
							`\n${data.receiver},${data.amount},${data.remark},${data.first_name},${data.last_name},${data.gender},${data.date_of_birth},${data.email}${form_data}`
						);
					} else {
						incorrect_csv.push(
							`\n${data.receiver},${data.points},${data.remark},${data.first_name},${data.last_name},${data.gender},${data.date_of_birth},${data.email}${form_data}`
						);
					}
				} else if (type === "membercard") {
					incorrect_csv.push(
						`\n${data.receiver},${data.card_name},${data.first_name},${data.last_name},${data.gender},${data.date_of_birth},${data.email}${form_data}`
					);
				}
			} else {
				if (type === "point") {
					if (importType === "product") {
						incorrect_csv.push(`\n${data.receiver},${data.amount},${data.sku},${data.receipt_no},${data.remark}`);
					} else if (importType === "amount") {
						incorrect_csv.push(`\n${data.receiver},${data.amount},${data.remark}`);
					} else {
						incorrect_csv.push(`\n${data.receiver},${data.points},${data.remark}`);
					}
				}
			}
		});
		jsonToCsv(incorrect_csv, "incorrect_data.csv");
	};

	useEffect(() => {
		if (!_.isEmpty(prepareJobs)) {
			const formIdList = business?.custom_form ? form_setting.map((form) => { return { id: form.id, required: form.is_required, options: form.options_list } }) : [];
			const result = verifyPrepareData(prepareJobs, type, memberCardInfoList, importType, customRoute, formIdList);
			if (result) {
				setJobs({
					incorrect: result.filter((value) => !value.result),
					correct: result.filter((value) => value.result),
				});
			} else {
				setJobs({});
			}
		}
	}, [prepareJobs]); // eslint-disable-line react-hooks/exhaustive-deps

	if (!store_jobs && store_jobs.prepareJobs) return null;
	return (
		!_.isEmpty(jobs) && (
			<Card className="mt-3">
				<CardHeader className="d-flex justify-content-between">
					<div>
						<Title2 bold>{dictionary.show_allData}</Title2>
						{type === "point" && (
							<Title3 secondary>{`${dictionary.import_type} : ${dictionary[store_jobs.type]}`}</Title3>
						)}
					</div>
					<div className="mt-2" hidden={_.isEmpty(jobs.incorrect)}>
						<Title3 link blue bold onClick={() => exportCSV(jobs.incorrect)}>
							{dictionary.modal_btn_download}
						</Title3>
					</div>
				</CardHeader>
				<CardBody className="pt-0">
					<div hidden={_.isEmpty(jobs.incorrect)}>
						<div className="mt-3 mb-2 d-flex justify-content-between">
							<Title3 bold danger>
								{`${dictionary.incorrect_data}`}
							</Title3>
							<Title3 bold>{`${dictionary.total} ${toCurrency(_.size(jobs.incorrect))}`}</Title3>
						</div>
						<PreDataTable data={jobs.incorrect} pointType={store_jobs.type} type={type} customRoute={customRoute} />
					</div>
					<div>
						<div className="mt-3 mb-2 d-flex justify-content-between">
							<Title3 bold> {`${dictionary.correct_data}`}</Title3>
							<Title3 bold> {`${dictionary.total} ${toCurrency(_.size(jobs.correct))}`} </Title3>
						</div>
						<PreDataTable data={jobs.correct} pointType={store_jobs.type} type={type} customRoute={customRoute} />
						{store_jobs.type === "product" ? (
							<div className="mt-3">
								<Title3 secondary>{dictionary.product_point}</Title3>
							</div>
						) : (
							""
						)}
						<div hidden={_.isEmpty(jobs.correct)}>
							<BtnSubmit>
								<Button
									id={`btn-submit`}
									className="btn-success"
									disabled={store_jobs.isProcess || message.isError}
									onClick={submitJobsData}
								>
									<Title2 bold white>
										{" "}
										{dictionary.submit}
									</Title2>
								</Button>
							</BtnSubmit>
						</div>

						{modalIncorrectData && !downloadAlready && (
							<>
								<Modal isOpen={modalIncorrectData} toggle={toggle} className="" backdrop={"static"}>
									<ModalHeader toggle={toggle}>
										<Title1 bold>{dictionary.modal_title_incomplete_import_data}</Title1>
									</ModalHeader>
									<ModalBody>
										<Title2>{dictionary.modal_detail_please_verify}</Title2>
										<div className="mt-2">
											<Title2 onClick={() => exportCSV(jobs.incorrect)} link blue>
												{dictionary.modal_btn_download}
											</Title2>
										</div>
									</ModalBody>
									<ModalFooter>
										<Title3 link className="mr-1" onClick={toggle}>
											{dictionary.modal_btn_cancel}
										</Title3>
										<Button className="btn-secondary" onClick={confirmSubmitData}>
											<Title3 white bold>{`${dictionary.modal_btn_continue}`}</Title3>
										</Button>
									</ModalFooter>
								</Modal>
							</>
						)}
					</div>
				</CardBody>
			</Card>
		)
	);
}

const BtnSubmit = styled.div`
	height: 40px;
	display: flex;
	padding-top: 15px;
	align-items: center;
	justify-content: center;
`;

const combineData = (data, type) => {
	let tasks = {};
	let info = [];
	let id = 0;
	data.forEach((item) => {
		if (tasks[item.receipt_no]) {
			tasks[item.receipt_no].push(item);
		} else {
			tasks[item.receipt_no] = [];
			tasks[item.receipt_no].push(item);
		}
	});
	Object.keys(tasks).forEach((item, index) => {
		let sum_point = 0;
		let sum_amount = 0;
		let data = tasks[item];
		let receiver = "";
		let receipt_no = "";
		let sku_detail = [];
		let new_data;
		
		id += 1;
		for (let i = 0; i < data.length; i++) {
			sum_point += parseFloat(data[i].points);
			sum_amount += parseFloat(data[i].amount);
			receiver = data[i].receiver;
			receipt_no = data[i].receipt_no;
			sku_detail.push({
				sku: data[i].sku,
				amount: data[i].amount,
				points: data[i].points,
				product_detail: data[i].product_detail,
			});
		}
		if(type==='point'){
			new_data = 
				{
					amount: sum_amount.toString(),
					key: id,
					points: Math.floor(sum_point).toString(),
					receiver,
					remark: data[0].remark,
					sku_detail,
					receipt_no,
					first_name: data[0].first_name,
					last_name: data[0].last_name,
					gender: data[0].gender,
					date_of_birth: data[0].date_of_birth,
					email: data[0].email,
					custom_form: data[0].custom_form,
				}		
		}else{
			new_data = 
				{
					amount: sum_amount.toString(),
					key: id,
					points: Math.floor(sum_point).toString(),
					receiver,
					remark: data[0].remark,
					sku_detail,
					receipt_no,
				}
			
		}
		
		info.push(new_data);
	});
	return info;
};

const verifyPrepareData = (data, type = "point", memberCardInfoList, importType, customRoute, formIdList) => {
	return (
		data &&
		data.map((value, key) => {
			let temp =
				type === "point" ? validatePointData(value, importType, customRoute, formIdList) : validateMemberCardData(value, memberCardInfoList, formIdList);
			return Object.assign(value, { key: key, result: temp.result, danger: temp.danger }, temp.value);
		})
	);
};

const validatePointData = (data, importType, customRoute, formIdList) => {
	const isPointsCorrect = importType === "points" ? pointsValidator(data) : pointsDecimalValidator(data);
	const isReceiverCorrect = customRoute === "/jobsMemberCard" ? mobileValidator(data.receiver) : receiverValidator(data.receiver);
	const isAmountCorrect = (data.amount === undefined || data.amount === "") ? importType === "points" ? true : false : (!data.amount || amountValidator(data.amount))
	const isFirstNameCorrect = !data.first_name || _.size(data.first_name) <= 50;
	const isLastNameCorrect = !data.last_name || _.size(data.last_name) <= 50;
	const isGenderCorrect = !data.gender || ["male", "female", "unspecified"].indexOf(data.gender.toLowerCase()) > -1;
	const isDateOfBirthCorrect = !data.date_of_birth || (verifyDateOfBirth(data.date_of_birth) && checkIsValidDate(data.date_of_birth));
	const isAgeCorrect =
		!data.date_of_birth || (isDateOfBirthCorrect && verifyAge(convertYearOfBirth(data.date_of_birth)));
	const isEmailCorrect = !data.email || verifyEmail(data.email);
	const isSKUCorrect = data.sku_check !== undefined ? (data.sku === "" ? false : data.sku_check) : true;
	const isSameReceiver =
		data.receipt_check !== undefined ? (data.receipt_no === "" ? false : data.receipt_check) : true;
	const dangerForm = [];
	const customForm = [];
	const isCustomFormCorrect = customRoute === "/jobsMemberCard" ? formIdList.map((form) => {
		let resForm = true;
		if (form.required && _.isEmpty(data[`custom_form_${form.id}`])) {
			dangerForm.push(`custom_form_${form.id}`);
			resForm = false;
		}
		if (form.options && !_.isEmpty(data[`custom_form_${form.id}`])) {
			const optionsCheck = form.options.find((op) => op.title === data[`custom_form_${form.id}`]);
			if (!optionsCheck) {
				dangerForm.push(`custom_form_${form.id}`);
				resForm = false;
			}
		}

		customForm.push({ form_id: form.id, value: data[`custom_form_${form.id}`] });
		return resForm
	}) : [];
	return {
		value: {
			date_of_birth: isDateOfBirthCorrect ? convertYearOfBirth(data.date_of_birth) : data.date_of_birth,
			custom_form: customForm,
		},
		result:
			isPointsCorrect &&
			isReceiverCorrect &&
			isFirstNameCorrect &&
			isLastNameCorrect &&
			isGenderCorrect &&
			isDateOfBirthCorrect &&
			isEmailCorrect &&
			isAgeCorrect &&
			isSKUCorrect &&
			isSameReceiver &&
			isAmountCorrect &&
			_.every(isCustomFormCorrect, Boolean),
		danger: [
			!isPointsCorrect && "points",
			!isReceiverCorrect && "receiver",
			!isFirstNameCorrect && "first_name",
			!isLastNameCorrect && "last_name",
			!isGenderCorrect && "gender",
			!isDateOfBirthCorrect && "date_of_birth",
			!isAgeCorrect && "age_range",
			!isEmailCorrect && "email",
			!isSKUCorrect && "sku",
			!isSameReceiver && "receipt_no",
			!isAmountCorrect && "amount",
			!_.every(isCustomFormCorrect, Boolean) && dangerForm.join("_"),
		].join("_"),
	};
};

const validateMemberCardData = (data, memberCardInfoList, formIdList) => {
	const card = _.find(memberCardInfoList, { card_name: data.card_name }) || {};
	const isReceiverCorrect = mobileValidator(data.receiver)
	const isCardNameCorrect = !!card.card_id;
	const isFirstNameCorrect = data.first_name && _.size(data.first_name) <= 50;
	const isLastNameCorrect = data.last_name && _.size(data.last_name) <= 50;
	const isGenderCorrect = !data.gender || ["male", "female", "unspecified"].indexOf(data.gender.toLowerCase()) > -1;
	const isDateOfBirthCorrect = !data.date_of_birth || (verifyDateOfBirth(data.date_of_birth) && checkIsValidDate(data.date_of_birth));
	const isAgeCorrect =
		!data.date_of_birth || (isDateOfBirthCorrect && verifyAge(convertYearOfBirth(data.date_of_birth)));
	const isEmailCorrect = !data.email || verifyEmail(data.email);
	const dangerForm = [];
	const customForm = [];
	const isCustomFormCorrect = formIdList.map((form) => {
		let resForm = true;
		if (form.required && _.isEmpty(data[`custom_form_${form.id}`])) {
			dangerForm.push(`custom_form_${form.id}`);
			resForm = false;
		}
		if (form.options && !_.isEmpty(data[`custom_form_${form.id}`])) {
			const optionsCheck = form.options.find((op) => op.title === data[`custom_form_${form.id}`]);
			if (!optionsCheck) {
				dangerForm.push(`custom_form_${form.id}`);
				resForm = false;
			}
		}

		customForm.push({ form_id: form.id, value: data[`custom_form_${form.id}`] });
		return resForm
	})
	return {
		value: {
			card_id: card.card_id,
			date_of_birth: isDateOfBirthCorrect ? convertYearOfBirth(data.date_of_birth) : data.date_of_birth,
			custom_form: customForm,
		},
		result:
			isReceiverCorrect &&
			isCardNameCorrect &&
			isFirstNameCorrect &&
			isLastNameCorrect &&
			isGenderCorrect &&
			isDateOfBirthCorrect &&
			isEmailCorrect &&
			isAgeCorrect &&
			_.every(isCustomFormCorrect, Boolean),
		danger: [
			!isReceiverCorrect && "receiver",
			!isCardNameCorrect && "card_name",
			!isFirstNameCorrect && "first_name",
			!isLastNameCorrect && "last_name",
			!isGenderCorrect && "gender",
			!isDateOfBirthCorrect && "date_of_birth",
			!isAgeCorrect && "age_range",
			!isEmailCorrect && "email",
			!_.every(isCustomFormCorrect, Boolean) && dangerForm.join("_"),
		].join("_"),
	};
};

const convertYearOfBirth = (dateOfBirth) => {
	if (!dateOfBirth) {
		return dateOfBirth;
	}

	const year = Number(dateOfBirth.split("-")[2]);
	if (year > moment().year()) {
		return dateOfBirth.replace(year, year - 543); // B.E. to C.E.
	}

	return dateOfBirth;
};

const checkIsValidDate = (dateOfBirth) => {
	const dateSplit = dateOfBirth.split("-");
	const dateForm = dateSplit.reverse().join("-");

	return moment(dateForm).isValid();
}