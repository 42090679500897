import axios from './../axios_request';
import { businessAPI } from '../end_point';
import { ERROR } from '../../libs/utils';
import { PRODUCT } from '../actions_const';
import { uploadFile, uploadPhoto } from '../../libs/Firebase';
import { getCookie } from '../../libs/cookies';

export const getProductCategory = ({ business_code, excludeArchive = true }, callback) => {
	let isFunc = typeof callback === 'function';
	return (dispatch) => {
		dispatch({ type: PRODUCT.IS_LOADING_GET_CATEGORY });
		axios({
			method: 'get',
			url: `${businessAPI}/${business_code}/product/category?excludeArchive=${excludeArchive}`,
			headers: { Authorization: getCookie('_token') },
		})
			.then(({ data }) => {
				if (data.result) {
					if (isFunc) callback(null, data.data);
					return dispatch({ type: PRODUCT.GET_CATEGORY_PRODUCT, data: data.data });
				} else return Promise.reject((data && data.error) || 'result error');
			})
			.catch((err) => {
				ERROR('getProductCategory', err);
				if (isFunc) callback(err);
				return dispatch({ type: PRODUCT.GET_CATEGORY_PRODUCT });
			});
	};
};

export const createProductCategory = (input, callback) => {
	let { business_code, category_name, is_default } = input;
	let isFunc = typeof callback === 'function';
	return (dispatch) => {
		dispatch({ type: PRODUCT.IS_LOADING_UPDATE_CATEGORY });
		axios({
			method: 'post',
			url: `${businessAPI}/${business_code}/product/category`,
			headers: { Authorization: getCookie('_token') },
			data: { category_name, is_default },
		})
			.then(({ data }) => {
				if (data.result) {
					if (isFunc) callback(null, data.data);
					return dispatch({ type: PRODUCT.CREATE_CATEGORY_PRODUCT, data: data.data });
				} else return Promise.reject((data && data.error) || 'result error');
			})
			.catch((err) => {
				ERROR('createProductCategory', err);
				if (isFunc) callback(err);
				return dispatch({ type: PRODUCT.CREATE_CATEGORY_PRODUCT });
			});
	};
};

export const updateProductCategory = (input, callback) => {
	let { business_code, id, category_name } = input;
	let isFunc = typeof callback === 'function';
	return (dispatch) => {
		dispatch({ type: PRODUCT.IS_LOADING_UPDATE_CATEGORY });
		axios({
			method: 'put',
			url: `${businessAPI}/${business_code}/product/category`,
			headers: { Authorization: getCookie('_token') },
			data: { id, category_name },
		})
			.then(({ data }) => {
				if (data.result) {
					if (isFunc) callback(null, data.data);
					return dispatch({ type: PRODUCT.UPDATE_CATEGORY_PRODUCT, data: data.data });
				} else return Promise.reject((data && data.error) || 'result error');
			})
			.catch((err) => {
				ERROR('updateProductCategory', err);
				if (isFunc) callback(err);
				return dispatch({ type: PRODUCT.UPDATE_CATEGORY_PRODUCT });
			});
	};
};

export const deleteProductCategory = (input, callback) => {
	let { business_code, id } = input;
	let isFunc = typeof callback === 'function';
	return (dispatch) => {
		dispatch({ type: PRODUCT.IS_LOADING_UPDATE_CATEGORY });
		axios({
			method: 'delete',
			url: `${businessAPI}/${business_code}/product/category`,
			headers: { Authorization: getCookie('_token') },
			data: { id },
		})
			.then(({ data }) => {
				if (data.result) {
					if (isFunc) callback(null, data.data);
					return dispatch({ type: PRODUCT.DELETE_CATEGORY_PRODUCT, data: data.data });
				} else return Promise.reject((data && data.error) || 'result error');
			})
			.catch((err) => {
				ERROR('deleteProductCategory', err);
				if (isFunc) callback(err);
				return dispatch({ type: PRODUCT.DELETE_CATEGORY_PRODUCT });
			});
	};
};

export const getProducts = ({ business_code, excludeArchive = '' }, callback) => {
	let isFunc = typeof callback === 'function';
	return (dispatch) => {
		dispatch({ type: PRODUCT.IS_LOADING_GET_PRODUCT });
		axios({
			method: 'get',
			url: `${businessAPI}/${business_code}/product?excludeArchive=${excludeArchive}`,
			headers: { Authorization: getCookie('_token') },
		})
			.then(({ data }) => {
				if (data.result) {
					if (isFunc) callback(null, data.data);
					return dispatch({ type: PRODUCT.GET_PRODUCT, data: data.data });
				} else return Promise.reject((data && data.error) || 'result error');
			})
			.catch((err) => {
				ERROR('getProduct', err);
				if (isFunc) callback(err);
				return dispatch({ type: PRODUCT.GET_PRODUCT });
			});
	};
};

export const getProductsByID = ({ business_code, products_id = [] }, callback) => {
	let isFunc = typeof callback === 'function';
	return (dispatch) => {
		dispatch({ type: PRODUCT.IS_LOADING_GET_PRODUCT });
		axios({
			method: 'post',
			url: `${businessAPI}/${business_code}/product/byIDs`,
			headers: { Authorization: getCookie('_token') },
			data: {
				products: products_id,
			},
		})
			.then(({ data }) => {
				if (data.result) {
					if (isFunc) callback(null, data.data);
					return data.data;
				} else return Promise.reject((data && data.error) || 'result error');
			})
			.catch((err) => {
				ERROR('getProductsByID', err);
				if (isFunc) callback(err);
				return [];
			});
	};
};

export const createProduct = (input, callback) => {
	let { business_code, image } = input;
	let isFunc = typeof callback === 'function';
	let imgPath = `${business_code}/product/${new Date().getTime()}.jpeg`;
	let img, link_url, path_location;

	return async (dispatch) => {
		dispatch({ type: PRODUCT.IS_LOADING_UPDATE_PRODUCT });
		try {
			if (image) {
				img = await uploadPhoto(imgPath, image);
				if (!img.url) throw new Error('Image url error');
				link_url = img.url;
				path_location = imgPath;
			}

			delete input.image;
			let { data } = await axios({
				method: 'post',
				url: `${businessAPI}/${business_code}/product`,
				headers: { Authorization: getCookie('_token') },
				data: Object.assign({}, input, { link_url: link_url, path_location: path_location }),
			});

			if (data.result) {
				if (isFunc) callback(null, data.data);
				return dispatch({ type: PRODUCT.CREATE_PRODUCT, data: data.data });
			} else throw (data && data.error) || new Error('result error');
		} catch (error) {
			ERROR('createProduct', error);
			if (isFunc) return callback(error);
			return dispatch({ type: PRODUCT.CREATE_PRODUCT });
		}
	};
};

export const updateProduct = (input, callback) => {
	let { business_code, image, isEditImage, path_location } = input;
	let isFunc = typeof callback === 'function';
	let imgPath = path_location || `${business_code}/product/${new Date().getTime()}.jpeg`;
	let img,
		link_url = image,
		path = path_location;

	return async (dispatch) => {
		try {
			dispatch({ type: PRODUCT.IS_LOADING_UPDATE_PRODUCT });
			if (isEditImage && image) {
				img = await uploadPhoto(imgPath, image);
				if (!img.url) throw new Error('Image url error');
				link_url = img.url;
				path = imgPath;
			}

			let { data } = await axios({
				method: 'put',
				url: `${businessAPI}/${business_code}/product`,
				headers: { Authorization: getCookie('_token') },
				data: Object.assign({}, input, { link_url: link_url || null, path_location: !image ? path_location : path }),
			});

			if (data.result) {
				if (isFunc) callback(null, data.data);
				return dispatch({ type: PRODUCT.UPDATE_PRODUCT, data: data.data });
			} else throw (data && data.error) || new Error('result error');
		} catch (error) {
			ERROR('updateProduct', error);
			if (isFunc) return callback(error);
			return dispatch({ type: PRODUCT.UPDATE_PRODUCT });
		}
	};
};

export const deleteProduct = (input, callback) => {
	let { business_code, product_id } = input;
	let isFunc = typeof callback === 'function';
	return (dispatch) => {
		dispatch({ type: PRODUCT.IS_LOADING_UPDATE_PRODUCT });
		axios({
			method: 'delete',
			url: `${businessAPI}/${business_code}/product`,
			headers: { Authorization: getCookie('_token') },
			data: { product_id },
		})
			.then(({ data }) => {
				if (data.result) {
					if (isFunc) callback(null, data.data);
					return dispatch({ type: PRODUCT.DELETE_PRODUCT, data: data.data });
				} else return Promise.reject((data && data.error) || 'result error');
			})
			.catch((err) => {
				ERROR('deleteProduct', err);
				if (isFunc) callback(err);
				return dispatch({ type: PRODUCT.DELETE_PRODUCT });
			});
	};
};

export const moveAllProductFromCat2Cat = (input, callback) => {
	let { business_code, source, target } = input;
	let isFunc = typeof callback === 'function';
	return (dispatch) => {
		dispatch({ type: PRODUCT.IS_LOADING_UPDATE_PRODUCT });
		axios({
			method: 'put',
			url: `${businessAPI}/${business_code}/product/moveCategory`,
			headers: { Authorization: getCookie('_token') },
			data: { source, target },
		})
			.then(({ data }) => {
				if (data.result) {
					if (isFunc) callback(null, data.data);
					return dispatch({ type: PRODUCT.MOVE_ALL_PRODUCT_CATEGORY, data: data.data });
				} else return Promise.reject((data && data.error) || 'result error');
			})
			.catch((err) => {
				ERROR('moveAllProductInCategory', err);
				if (isFunc) callback(err);
				return dispatch({ type: PRODUCT.MOVE_ALL_PRODUCT_CATEGORY });
			});
	};
};

export const importProducts = (input) => {
	return new Promise(async (resolve, reject) => {
		const { businessCode, categoryId, file } = input;
		const fileName = `${new Date().getTime()}.csv`;
		const filePath = `${businessCode}/import_product/${categoryId}/${fileName}`;

		try {
			const uploadResult = await uploadFile(filePath, file);
			if (!uploadResult.url) throw new Error('Upload .csv error');

			const { data } = await axios({
				method: 'post',
				url: `${businessAPI}/${businessCode}/product/import`,
				headers: { Authorization: getCookie('_token') },
				data: {
					category: categoryId,
					file_name: fileName,
				},
			});

			if (data.result) {
				resolve();
			} else {
				throw (data && data.error) || new Error('result error');
			}
		} catch (error) {
			ERROR('importProducts', error);
			reject();
		}
	});
};

export const archiveImportStatus = (input) => {
	return new Promise(async (resolve, reject) => {
		const { businessCode, categoryId } = input;

		try {
			const { data } = await axios({
				method: 'post',
				url: `${businessAPI}/${businessCode}/product/archiveImportStatus`,
				headers: { Authorization: getCookie('_token') },
				data: {
					category: categoryId,
				},
			});

			if (data.result) {
				resolve();
			} else {
				throw (data && data.error) || new Error('result error');
			}
		} catch (error) {
			ERROR('archiveImportStatus', error);
			reject();
		}
	});
};

export const checkBusinessHasProduct = ({ business_code }) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${businessAPI}/${business_code}/product/checkHasProduct`,
      headers: { Authorization: getCookie("_token") },
    })
      .then(({ data }) => {
        dispatch({ type: PRODUCT.SET_HAS_PRODUCT, data: data.data[0].has_row });
      })
      .catch((err) => {
        dispatch({ type: PRODUCT.SET_HAS_PRODUCT, data: null });
      });
  };
};