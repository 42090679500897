import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormGroup, Input, Row } from 'reactstrap'
import { get } from 'lodash';
import { useGetTargetRFMGroups, useHandleSetTargetMemberTotal } from './hooks';
import { setTargetMemberTotal } from '../../../redux/actions/action_broadcast';
import { Title2 } from '../../Font'
import { templateRFM } from '../../../libs/rfm';
import { rangeDates } from '../../../libs/targetGroups';
import { usePrevious } from '../../../libs/hooks/usePrevious';

export default function TargetRFM({
	target,
	disabled,
	noRequest,
	showAsLabel,
	isEditBroadcast
}) {
	const dispatch = useDispatch()
	const dictionary = useSelector((state) => state.language.dictionary)
	const [targetRange, setTargetRange] = useGetTargetRFMGroups({ init: target?.range || '', noRequest: noRequest })
	const previousRange = usePrevious(targetRange)
	const [targetSegment, setTargetSegment, memoRFMgroups] = useHandleSetTargetMemberTotal(isEditBroadcast ? '' : target?.segment || '')
	const previousSegment = usePrevious(targetSegment)
	const RFM = templateRFM
	
	useEffect(() => {
		if (!noRequest) {
			if (targetSegment !== previousSegment) {
				dispatch(setTargetMemberTotal({
					target: get(memoRFMgroups, 'range', 'rfm_groups_6m'),
					target_data: memoRFMgroups.segment_id || targetSegment || 0,
					...memoRFMgroups
				}))
			}
		} else {
			dispatch(setTargetMemberTotal({
				target: targetRange || 'rfm_groups',
				target_data: targetSegment
			}))
		}
	}, [dispatch, memoRFMgroups, targetRange, previousRange, targetSegment, previousSegment, noRequest])

	useEffect(() => {
		if (isEditBroadcast) {
			setTimeout(() => {
				setTargetSegment(target?.segment || '')
			}, 200)
		}
	}, [])

	const handleChangeSegment = (e) => {
		let { value } = e.target
		setTargetSegment(value)
	}

	const handleChangeRange = (e) => {
		let { value } = e.target
		setTargetRange(value)
		setTargetSegment('')
	}

	return (
		<FormGroup>
			<Title2 className='pb-2' bold>
				<span className="text-danger">* </span>
				{dictionary.select_rfm_group}
			</Title2>
				{
					showAsLabel
					? <Row>
							<Col>
								<RenderTargetValueAsLabel range={targetRange} segment={targetSegment} />
							</Col>
						</Row>
					: <Row>
							<Col xs={5} className="pb-1">
								<Input
									type="select"
									name="range"
									value={targetRange}
									onChange={handleChangeRange}
									disabled={disabled}
								>
									<option disabled value="" >-- { dictionary.choose } --</option>
									{rangeDates.map((v, k) => (
										<option value={v} key={`${v}_${k}`}>
											{dictionary[v]}
										</option>
									))}
								</Input>
							</Col>
							<Col xs={7} className="pb-1">
								{
									<Input
										type="select"
										name="segment"
										value={targetSegment}
										onChange={handleChangeSegment}
										disabled={disabled}
									>
										<option disabled value="" >-- { dictionary.choose } --</option>
										{
											RFM.map((data, key) => {
												return (
													<option value={data.segment_group_id} key={`${data.segment_group_id}_${key}`}>
														{dictionary.RFM_list[data.RFM_key] && dictionary.RFM_list[data.RFM_key].name}
													</option>
												)
											})
										}
									</Input>
								}
							</Col>
						</Row>
				}
		</FormGroup>
	)
}

const RenderTargetValueAsLabel = ({ range, segment }) => {
	const dictionary = useSelector((state) => state.language.dictionary)
	const rfmKey = templateRFM.filter((item) => item.segment_group_id === parseInt(segment))

	return (
		rfmKey.map((rfm) => <Title2 key={rfm.RFM_key} className='pl-3' secondary>{`${dictionary[range]} / ${dictionary.RFM_list[rfm.RFM_key].name}`}</Title2> )
	)
}