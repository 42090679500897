import React, { useEffect, useState } from 'react';
import { Title1, Title2, Title3 } from '../../../Font';
import { DropdownCardBoxBottom, PackageTitle } from '../../BusinessPackage.style';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../../../../redux/actions/action_permission';
import { formatDateTime } from '../../../../libs/date';
import moment from 'moment';
import { exportUsageReport } from '../../../../redux/actions/action_usage';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { PrimaryBtn } from '../../../Button';

const ModalBodyBusinessPackage = (props) => {
  const { togglePackage, packageItems } = props;
  const dispatch = useDispatch();
  const { dictionary } = useSelector((state) => state.language);
  const { current } = useSelector((state) => state.business);
  const { profile } = useSelector((state) => state.permission);
  const [isOpenAnnualOptions, setIsOpenAnnualOptions] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [isSendedEmail, setIsSendedEmail] = useState(false);
  const this_permission = (current.permission && current.permission['setting-packages']) || {};
  const showMenu = (event) => {
    event.preventDefault();
    setIsOpenAnnualOptions(true);
  };

  const closeMenu = (event) => {
    setIsOpenAnnualOptions(false);
  };

  useEffect(() => {
    if (!isOpenAnnualOptions) return;
    document.addEventListener('click', closeMenu);
    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, [isOpenAnnualOptions]);

  const openWindow = async (url, target) => {
    try {
      await dispatch(checkLogin());
      window.open(url, target || 'Pointspot');
    } catch (error) {
      console.error(error);
    }
    setIsOpenAnnualOptions(false);
  };

  const exportToEmail = () => {
    setIsOpenModal(true);
    setIsLoadingExport(true);
    let colSpan = getColSpanByFeature(current);
    const startMonth = moment().startOf('month');
    const endMonth = moment().endOf('month');
    const dataHeaders = [
      {
        title: dictionary.usage_report,
        colSpan: colSpan
      },
      {
        title: `${dictionary.business} : ${current.business_name}`,
        colSpan: colSpan
      },
      {
        title: `${dictionary.create_date} : ${formatDateTime(new Date())}`,
        colSpan: colSpan
      },
      {
        title: `${dictionary.date_range} ${dictionary.all_time} : ${
          dictionary.date_on
        } ${formatDateTime(startMonth)} ${dictionary.to} ${formatDateTime(endMonth)}`,
        colSpan: colSpan
      }
    ];
    let usageColumns = getUsageColumns(dictionary, current);
    dataHeaders.push({ usageColumns });
    let columns = getColumnsWithKey(dictionary, current);
    dataHeaders.push({ columns });

    let _data = {
      business_code: current.business_code,
      start_date: startMonth.format('YYYY-MM-DD 00:00:00'),
      end_date: endMonth.format('YYYY-MM-DD 00:00:00'),
      language: dictionary.locale,
      send_to_email: {
        email: profile.email,
        data_headers: dataHeaders
      }
    };

    dispatch(
      exportUsageReport(_data, () => {
        setIsLoadingExport(false);
        return setIsSendedEmail(true);
      })
    );
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: '10px 10px 20px 10px' }}
      >
        <Title2 bold>{dictionary.package}</Title2>
        <div className="d-flex justify-content-center align-items-center" style={{ flex: 1 }}>
          <PackageTitle>{dictionary[packageItems.title_dictionary].toUpperCase()}</PackageTitle>
        </div>
        <div style={{ height: 'fit-content', width: 15 }}>
          <Title1 center link onClick={(e) => showMenu(e)}>
            <i className="fas fa-ellipsis-v" />
          </Title1>
          <DropdownCardBoxBottom hidden={!isOpenAnnualOptions}>
            <Title3
              className="pb-0 px-2 pt-2"
              link
              onClick={() => exportToEmail()}
              hidden={!Boolean(this_permission && this_permission.permission_export)}
            >
              {dictionary.export_data}
            </Title3>
            <Title3 className="p-2" link onClick={() => togglePackage()}>
              {dictionary.change_subscribed_package}
            </Title3>
            <Title3
              className="px-2"
              link
              onClick={() => openWindow(`${process.env.REACT_APP_RP_ACCOUNT}/usages`, 'RPAuth')}
            >
              {dictionary.usage_history}
            </Title3>
            <Title3
              className="p-2"
              link
              onClick={() => openWindow(`${process.env.REACT_APP_RP_ACCOUNT}/balance`, 'RPAuth')}
            >
              {dictionary.credit_purchase_history}
            </Title3>
          </DropdownCardBoxBottom>
        </div>
      </div>

      <SendedModal
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(false)}
        isLoadingBtnExport={isLoadingExport}
        isSuccess={isSendedEmail}
        profile={profile}
      />
    </>
  );
};

export default ModalBodyBusinessPackage;

const SendedModal = ({ isOpen, toggle, isLoadingBtnExport, isSuccess, profile }) => {
  const dictionary = useSelector((state) => state.language.dictionary);

  return (
    <Modal isOpen={isOpen} centered>
      <ModalBody className="text-center">
        {isLoadingBtnExport ? (
          <Title2 className="p-3">{dictionary.exporting}</Title2>
        ) : isSuccess ? (
          <Title2 className="p-3">
            {dictionary.report_will_send_to_email_after_processed}
            {<Title2 bold success>{`( ${profile.email} )`}</Title2>}
          </Title2>
        ) : (
          <Title2 bold danger>
            {dictionary.error_occurred}
          </Title2>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-end">
        {isLoadingBtnExport ? (
          <PrimaryBtn disabled bold md>
            <i className="fas fa-spinner fa-pulse" />
          </PrimaryBtn>
        ) : (
          <PrimaryBtn bold md onClick={toggle}>
            {dictionary.close}
          </PrimaryBtn>
        )}
      </ModalFooter>
    </Modal>
  );
};

const getColSpanByFeature = (b_info) => {
  let colSpan = 7;
  if (b_info?.member_card) {
    colSpan += 2;
  }
  if (b_info?.rejected_collect_points_noti) {
    colSpan += 2;
  }
  if (b_info?.expiry_point.noti_type_expiry_point) {
    colSpan += 2;
  }
  if (b_info?.member_card_noti_expiry_setting_type) {
    colSpan += 2;
  }
  return colSpan;
};

const getUsageColumns = (dictionary, b_info) => {
  let columns = [
    {
      title: dictionary.date
    },
    {
      title: dictionary.points,
      colSpan: 2
    },
    {
      title: dictionary.ecoupon,
      colSpan: 2
    },
    {
      title: dictionary.broadcast,
      colSpan: 2
    }
  ];
  if (b_info?.member_card) {
    columns.push({
      title: dictionary.member_card,
      colSpan: 2
    });
  }
  if (b_info?.rejected_collect_points_noti) {
    columns.push({
      title: dictionary.collect_point_noti,
      colSpan: 2
    });
  }
  if (b_info?.expiry_point.noti_type_expiry_point) {
    columns.push({
      title: dictionary.points_expiration_noti,
      colSpan: 2
    });
  }
  if (b_info?.member_card_noti_expiry_setting_type) {
    columns.push({
      title: dictionary.member_card_expire_notification,
      colSpan: 2
    });
  }
  columns.push({
    title: dictionary.broadcast_billing_total,
    colSpan: 1
  });
  return columns;
};

const getColumnsWithKey = (dictionary, b_info) => {
  let columns = [
    {
      title: '',
      dataKey: 'date'
    },
    {
      title: 'Transaction',
      dataKey: 'points_tx'
    },
    {
      title: dictionary.credit,
      dataKey: 'points_credit'
    },
    {
      title: 'Transaction',
      dataKey: 'coupon_tx'
    },
    {
      title: dictionary.credit,
      dataKey: 'coupon_credit'
    },
    {
      title: 'Transaction',
      dataKey: 'broadcast_tx'
    },
    {
      title: dictionary.credit,
      dataKey: 'broadcast_credit'
    }
  ];
  if (b_info?.member_card) {
    columns.push(
      {
        title: 'Transaction',
        dataKey: 'card_tx'
      },
      {
        title: dictionary.credit,
        dataKey: 'card_credit'
      }
    );
  }
  if (b_info?.rejected_collect_points_noti) {
    columns.push(
      {
        title: 'Transaction',
        dataKey: 'collect_points_tx'
      },
      {
        title: dictionary.credit,
        dataKey: 'collect_points_credit'
      }
    );
  }
  if (b_info?.expiry_point.noti_type_expiry_point) {
    columns.push(
      {
        title: 'Transaction',
        dataKey: 'expiry_point_tx'
      },
      {
        title: dictionary.credit,
        dataKey: 'expiry_point_credit'
      }
    );
  }
  if (b_info?.member_card_noti_expiry_setting_type) {
    columns.push(
      {
        title: 'Transaction',
        dataKey: 'expiry_card_tx'
      },
      {
        title: dictionary.credit,
        dataKey: 'expiry_card_credit'
      }
    );
  }
  return columns;
};
