import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { Title1, Title2, Title3, Title4 } from "../../../Font";
import { toCurrency2 } from "../../../../libs/currency";
import BusinessUsageOverviewChartTransaction from "./Chart/BusinessUsageOverviewChartTransaction";
import BusinessUsageOverviewChartCredit from "./Chart/BusinessUsageOverviewChartCredit";
import BusinessUsageChartLegend from "./Chart/BusinessUsageChartLegend";
import ContactUsModal from "../../ContactUsModal";
import useNormalTransactionData from "./Chart/hooks/useNormalTransactionData";
import useOverageTransactionData from "./Chart/hooks/useOverageTransactionData";

const ModalBodyBusinessUsageOverview = () => {
  const [isTopupModalOpen, setIsTopupModalOpen] = useState(false);
  const [key, setKey] = useState(0);

  const { subscription } = useSelector((state) => state.packages);
  const { dictionary } = useSelector((state) => state.language);
  const usage = useSelector((state) => state.usage);

  const { itemsUsage, freeTransactions } = subscription;
  const normalTransaction = itemsUsage?.find((item) => item.title_dictionary === "normal_transaction");

  const { transaction } = useNormalTransactionData();
  const { credit } = useOverageTransactionData();

  const toggleTopupModal = useCallback(() => setIsTopupModalOpen(prev => !prev), []);

  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [credit]);

  const renderNormalTransactionSection = () => (
    <div className="p-2">
      <Title3 bold className="p-0 m-0 pl-2">{dictionary.chart_normal_tx}</Title3>
      <Row className="pl-2 pr-2">
        <Col xs={12} md={10} lg={10} className="d-flex flex-column justify-content-center align-items-center">
          <BusinessUsageOverviewChartTransaction transaction={transaction} />
        </Col>
        <Col xs={12} md={2} lg={2} className="d-flex flex-column justify-content-center">
          <div className="d-flex flex-column justify-content-center">
            <Title1 bold>{toCurrency2(normalTransaction?.totalAmountTransactionUsage)}</Title1>
            <Title3 third>/{toCurrency2(freeTransactions)}</Title3>
          </div>
        </Col>
      </Row>
      {usage.usage_limit.isTxCloseToLimit && (
        <Row className="px-2">
          <Col xs={12} md={10} lg={10} className="d-flex flex-column justify-content-center align-items-end">
            <Title4 danger className="align-self-end">{dictionary.transactions_low}</Title4>
          </Col>
        </Row>
      )}
    </div>
  );

  const renderCreditSection = () => (
    <div className="p-2">
      <Title3 bold className="p-0 m-0 pl-2">
        {dictionary.chart_doughnut_credit_title}
      </Title3>
      <Row className="px-2">
        <Col
          xs={12}
          md={10}
          lg={10}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <BusinessUsageOverviewChartCredit key={key} credit={credit} />
        </Col>
        <Col xs={12} md={2} lg={2} className="d-flex flex-column justify-content-center">
          <div className="d-flex flex-column justify-content-center">
            {!(
              usage.usage_record.credit_addons.remaining === 0 &&
              usage.usage_record.credit_addons.usage === 0
            ) ? (
              <div className="d-flex flex-column justify-content-center">
                <Title1 bold>{toCurrency2(credit.total, 2)}</Title1>
                <Title3 third>/{toCurrency2(usage.usage_record.credit_total, 2)}</Title3>
              </div>
            ) : (
              <Button onClick={toggleTopupModal} className="bg-light border-0 text-secondary">
                {dictionary.chart_topup_credit}
              </Button>
            )}
          </div>
        </Col>
      </Row>
      {usage.usage_limit.isCreditCloseToLimit && (
        <Row className="px-2">
          <Col
            xs={12}
            md={10}
            lg={10}
            className="d-flex flex-column justify-content-center align-items-end"
          >
            <Title4 danger className="align-self-end">
              {dictionary.credit_low}
            </Title4>
          </Col>
        </Row>
      )}
    </div>
  );

  return (
    <>
      <ContactUsModal isOpen={isTopupModalOpen} toggleContact={toggleTopupModal} isPurchase={true} />
      <div className="d-flex flex-column bg-white rounded p-3">
        <Title2 bold className="p-2">{dictionary.chart_usage}</Title2>
        {renderNormalTransactionSection()}
        {renderCreditSection()}
        <div className="p-2">
          <BusinessUsageChartLegend transaction={transaction} overage={credit} type="Overview-Usage" />
        </div>
      </div>
    </>
  );
};

export default ModalBodyBusinessUsageOverview;