import React from 'react';
import parse from 'html-react-parser';
import parser, { Tag } from 'bbcode-to-react';

class YoutubeTag extends Tag {
  toReact() {
    const attributes = {
      src: `https://www.youtube.com/embed/${this.getContent(true)}`
    };
    return (
      <iframe
        title={attributes.src}
        {...attributes}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  }
}
parser.registerTag('youtube', YoutubeTag);

class ParagraphTag extends Tag {
  toReact() {
    const attributes = {};
    return <p {...attributes}>{this.getContent(true)}</p>;
  }
}
parser.registerTag('p', ParagraphTag);

export function nl2br(message) {
  message = message || '';
  message = message.replace(/\\n/g, '\n');
  return message
    ? message.split('\n').map((line, idx) => (
        <span key={idx}>
          {parser.toReact(line)}
          <br />
        </span>
      ))
    : '';
}

export const nl2brBroadcastMessage = (dictionary, message) => {
  if (!dictionary || !message) return null;

  const _key = ['name', 'link', 'point'];
  const key = {
    $name: "<code class='code-broadcast'>" + dictionary.member_name + '</code>',
    $link: "<code class='code-broadcast'>" + dictionary.business_link + '</code>',
    $point: "<code class='code-broadcast'>" + dictionary.points + '</code>'
  };

  message = message || '';
  message = message.replace(/\\n/g, '\n');

  return message
    ? message.split('\n').map((line, idx) => {
        if (!Boolean(line)) {
          return (
            <span key={idx}>
              {parser.toReact(line)}
              <br />
            </span>
          );
        }

        for (let index = 0; index < _key.length; index++) {
          const element = _key[index];
          const placeholder = `$${element}`;
          if (line.includes(placeholder)) {
            line = line.split(placeholder).join(key[placeholder]);
          }
        }

        return (
          <span key={idx}>
            {parse(line)}
            <br />
          </span>
        );
      })
    : '';
};
