import { PACKAGE, PERMISSION } from '../actions_const'
import _ from 'lodash'

let initState = {
  isLoading: false,
  isLoadingBusinessPackage: false,
  packages_list: [],
  packages: { package_data: {} },
  packages_free: ['ps-free', 'ps-free-trial', 'ps-free-trial-2023'],
  subscription: {},
  subscription_items: [],
  products: {},
  usageRecord: {},
  isProductPointsAddon: false,
}

const ReducerPackage = (state = initState, action) => {
  switch (action.type) {
    case PERMISSION.USER_LOGOUT_SUCCESS: 
      return initState;
    case PACKAGE.PACKAGE_IS_LOADING:
      return Object.assign({}, state, {
        isLoading: action.loading,
        subscription: {},
        subscription_items: {},
        products: {},
        usageRecord: {}
      })

    case PACKAGE.PACKAGE_IS_PROCESS:
      return Object.assign(state, { isLoadingBusinessPackage: true })

    case PACKAGE.GET_PACKAGES:
      {
        let packages = action.data.packages
        let package_data = _.get(state.packages, ['package_data']);
        let package_data_default = !_.isEmpty(package_data) ? package_data : getPackageData(packages, 'ps-free-trial-2023')

        return Object.assign(state, {
          packages_list: action.data.packages,
          packages: { package_data: package_data_default }
        })
      }

    case PACKAGE.GET_BUSINESS_PACKAGE:
      if (!action.data.result)
        return Object.assign({}, state, { isLoadingBusinessPackage: false })

      let package_data = Object.assign({}, action.data.package)
      let package_id = package_data.subscription_package || null
      if (action.data && action.data.package_info) {
        package_data.package_info = action.data.package_info
        if (!package_id) {
          package_id = package_data.package_info.package_id
        }
      }

      let packageItems = getPackageData(state.packages_list, package_id)
      package_data.package_data = packageItems
      const product_id = 'product_level_points'
      const product_feature = 'product_management'
      let subscription_items = _.get(package_data, ['subscription', 'items']) || []
      let availableAddon = _.get(package_data, ['package_info', 'addon']) || []
      let availableItems = _.get(package_data, ['package_info', 'items']) || []
      let packageFeature = _.get(package_data, ['package_info', 'feature']) || []
      subscription_items = _.filter([...availableAddon, ...availableItems], (item) => {
        return subscription_items.indexOf(item.item_id) > -1;
      });
      let isProductPointsAddon = false
      if (package_data.package_info.package_id && (package_data.package_info.package_id === 'ps-free-trial' || package_data.package_info.package_id === 'ps-free-trial-2023')) {
        isProductPointsAddon = Boolean(_.find(package_data.package_info.items, { filter_term: product_id }))
      } else {
        isProductPointsAddon = Boolean(_.find(subscription_items, { filter_term: product_id }) || packageFeature.indexOf(product_feature) > -1)
      }
      //console.log("isProductPointsAddon", isProductPointsAddon, subscription_items)
      return Object.assign({}, state, {
        packages: package_data,
        isLoadingBusinessPackage: false,
        isProductPointsAddon
      })

    case PACKAGE.GET_BUSINESS_SUBSCRIPTION:
      let data = action.data || {}
      return Object.assign({}, state, {
        subscription: Object.assign({}, state.subscription, data.subscription),
        subscription_items: data.subscription && data.subscription.items,
        usageRecord: Object.assign({}, state.usageRecord, data.usageRecord),
        products: Object.assign({}, state.products, data.products)
      })

    default:
      return state
  }
}

const getPackageData = (ps_package = [], package_id = 'ps-free-trial-2023') => ps_package.filter(v => v.package_id === package_id)[0]

export default ReducerPackage;