import { USAGE, PACKAGE, PERMISSION } from "../actions_const";

let initUsage = {
  free_members: 0,
  free_transactions: 0,
  tx_coupon: 0,
  tx_member: 0,
  tx_member_points: 0,
  tx_member_subscribe: 0,
  tx_pull: 0,
  tx_send: 0,
  tx_total: 0,
  tx_limit: 0,
  usage_credit: 0,
  free_credits: 0,
  credit_addons: {},
  credit_remaining: 0,
  credit_remaining_percent: 100,
  isOverTrans: false,
};

let initState = {
  isLoading: false,
  isLoadingOverTrans: false,
  package_free: {},
  usage_record: initUsage,
  usage_limit: {
    package_type: "Free trial",
    isOverUsage: false,
    isOverMember: false,
    isOverTransaction: false,
    isTxCloseToLimit: false,
    isCreditCloseToLimit: false,
  },
  business_limit: {},
};

const ReducerUsage =  (state = initState, action) => {
  switch (action.type) {
    case PERMISSION.USER_LOGOUT_SUCCESS:
      return initState;
    case PACKAGE.GET_PACKAGES:
      let packages = action.data.packages;
      let package_data_default = getPackageData(packages, "ps-free-trial-2023");
      return Object.assign(state, {
        package_free: package_data_default,
      });

    case USAGE.USAGE_RECORD_LOADING:
      return Object.assign({}, state, {
        isLoading: action.loading,
      });
    case USAGE.GET_USAGE_RECORD: {
      let new_usage = Object.assign({}, initUsage, action.data);
      let package_id = new_usage.current_package;

      let business_limit = Object.assign({}, state.package_free, {
        member: String(new_usage.free_members || 10),
        transaction: String(new_usage.free_transactions || 10),
        tx_total_limit: String(new_usage.free_tx_total || 0),
      });
      
      return Object.assign({}, state, {
      
        usage_record: Object.assign({}, state.usage_record, new_usage),
        usage_limit: Object.assign({}, state.usage_limit, checkUsageOverLimit(business_limit, new_usage, package_id), {
          isTxCloseToLimit: action.data.isTxCloseToLimit,
          isCreditCloseToLimit: action.data.isCreditCloseToLimit,
        }),
        business_limit: business_limit,
      });
    }
    case USAGE.GET_FREE_USAGE_OVER_LIMIT: {
      let new_usage = Object.assign({}, initUsage, action.data);
      let package_id = new_usage.current_package;

      let business_limit = Object.assign({}, state.package_free, {
        member: String(new_usage.free_members || 10),
        transaction: String(new_usage.free_transactions || 10),
        tx_total_limit: String(new_usage.free_tx_total || 0),
      });

      return Object.assign({}, state, {
        usage_limit: Object.assign({}, state.usage_limit, checkUsageOverLimit(business_limit, new_usage, package_id)),
        business_limit: business_limit,
      });
    }
    case USAGE.INCREASE_USAGE_RECORD:
      let send = 0,
        pull = 0;
      if (action.data.type === "send") send += 1;
      if (action.data.type === "pull") pull += 1;

      let usage = Object.assign({}, state.usage_record, {
        tx_send: parseInt(state.usage_record.tx_send || 0) + send,
        tx_pull: parseInt(state.usage_record.tx_pull || 0) + pull,
        tx_total: parseInt(state.usage_record.tx_total || 0) + 1,
      });

      return Object.assign({}, state, { usage_record: usage });

    case USAGE.LOADING_IS_OVER_TRANS:
      return Object.assign({}, state, { isLoadingOverTrans: true });

    case USAGE.GET_IS_OVER_TRANS:
      return Object.assign({}, state, {
        isLoadingOverTrans: false,
        usage_record: Object.assign({}, state.usage_record, { isOverTrans: action.data.isOverTrans, tx_remain: action.data.tx_remain }),
      });

    default:
      return state;
  }
};

const checkUsageOverLimit = ({ member: member_limit, transaction: transaction_limit, tx_total_limit }, { tx_member_subscribe = 0, tx_total = 0, tx_all_total = 0 }, package_id) => {
  let isOverMember = parseInt(tx_member_subscribe, 10) >= parseInt(member_limit, 10);
  let isOverTransaction = parseInt(tx_total, 10) >= parseInt(transaction_limit, 10);
  let isOverFreeTxTotal =
    package_id && (package_id === "ps-free-trial" || package_id === "ps-free-trial-2023") && tx_total_limit !== 0 && parseInt(tx_all_total, 10) >= parseInt(tx_total_limit, 10);

  return {
    isOverUsage: isOverMember || isOverTransaction || isOverFreeTxTotal,
    isOverMember,
    isOverTransaction,
    isOverFreeTxTotal,
  };
};

const getPackageData = (ps_package, package_id = "ps-free-trial-2023") => ps_package.filter((v) => v.show && v.package_id === package_id)[0];

export default ReducerUsage;