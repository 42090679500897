module.exports = {
  INITIAL_BUSINESS_STATE: 'INITIAL_BUSINESS_STATE',

  // Store Languages
  SELECT_LANGUAGE: 'SELECT_LANGUAGE',

  // Store wizard
  INIT_WALLET_OWNER: 'INIT_WALLET_OWNER',
  IS_OWNER_EXIST: 'IS_OWNER_EXIST',
  TOGGLE_CREATE_BUSINESS_MODAL: 'TOGGLE_CREATE_BUSINESS_MODAL',
  CLEAR_BUSINESS_CODE: 'CLEAR_BUSINESS_CODE',

  //Store business
  CREATE_BUSINESS: 'CREATE_BUSINESS',
  UPDATE_BUSINESS: 'UPDATE_BUSINESS',
  IS_CREATE_BUSINESS: 'IS_CREATE_BUSINESS',
  IS_CREATED_BUSINESS: 'IS_CREATED_BUSINESS',
  START_BUSINESS_PROCESS: 'START_BUSINESS_PROCESS',
  END_BUSINESS_PROCESS: 'END_BUSINESS_PROCESS',
  SET_BUSINESS_DROPDOWNLIST: 'SET_BUSINESS_DROPDOWNLIST',
  GET_BUSINESS_LIST_SUCCESS: 'GET_BUSINESS_LIST_SUCCESS',
  GET_BUSINESS_LIST_FAIL: 'GET_BUSINESS_LIST_FAIL',
  SET_CURRENT_BUSINESS: 'SET_CURRENT_BUSINESS',
  GET_BUSINESS_INFO_FAIL: 'GET_BUSINESS_INFO_FAIL',
  GET_BUSINESS_INFO_SUCCESS: 'GET_BUSINESS_INFO_SUCCESS',
  GET_BUSINESS_BALANCE: 'GET_BUSINESS_BALANCE',
  GET_TERMS_BUSINESS: 'GET_TERMS_BUSINESS',
  GET_POLICY_BUSINESS: 'GET_POLICY_BUSINESS',
  BUSINESS_SEND_POINT_SUCCESS: 'BUSINESS_SEND_POINT_SUCCESS',
  UPDATE_BUSINESS_CONTACT_BRANCH_PRIMARY:
    'UPDATE_BUSINESS_CONTACT_BRANCH_PRIMARY',
  GET_BUSINESS_REVIEW_PROCESS: ' GET_BUSINESS_REVIEW_PROCESS',
  GET_BUSINESS_REVIEW: ' GET_BUSINESS_REVIEW',
  GET_BUSINESS_REVIEW_MESSAGE: ' GET_BUSINESS_REVIEW_MESSAGE',
  GET_BUSINESS_POINTEXPIRY: ' GET_BUSINESS_POINTEXPIRY',
  GET_BUSINESS_MEMBER_CARD_EXPIRY_SETTING:
    'GET_BUSINESS_MEMBER_CARD_EXPIRY_SETTING',
  GET_BUSINESS_LINE_MEMBERS: 'GET_BUSINESS_LINE_MEMBERS',
  GET_BUSINESS_INTER_MEMBERS: 'GET_BUSINESS_INTER_MEMBERS',
  GET_BUSINESS_SHAPPY_MEMBERS: 'GET_BUSINESS_SHAPPY_MEMBERS',
  CLEAR_BUSINESS_REVIEW: 'CLEAR_BUSINESS_REVIEW',
  UPDATE_BUSINESS_CONFIG: 'UPDATE_BUSINESS_CONFIG',
  GET_BUSINESS_PINCODE: 'GET_BUSINESS_PINCODE',
  CREATE_BUSINESS_PINCODE: 'CREATE_BUSINESS_PINCODE',
  GET_MEMBER_CARD_INFO: 'GET_MEMBER_CARD_INFO',
  GET_BUSINESS_LABEL: 'GET_BUSINESS_LABEL',
  START_BUSINESS_EXPIRY_POINTS_PROCESS: 'START_BUSINESS_EXPIRY_POINTS_PROCESS',
  UPDATE_LINE_LIFF: 'UPDATE_LINE_LIFF',
  PERMISSION_DENIED: 'PERMISSION_DENIED',

  //Store modal
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  TOGGLE_MODAL_MEMBER: 'TOGGLE_MODAL_MEMBER',

  // store send_points
  START_SEARCHING: 'START_SEARCHING',
  START_SENDING: 'START_SENDING',
  CLEAR_PROFILE: 'CLEAR_PROFILE',
  GET_PROFILE_BY_MOBILE_OR_BADGE: 'GET_PROFILE_BY_MOBILE_OR_BADGE',
  SEND_POINT_TO_MEMBER: 'SEND_POINT_TO_MEMBER',
  SEND_POINT_IS_ERROR: 'SEND_POINT_IS_ERROR',
  CLEAR_SEND_POINT: 'CLEAR_SEND_POINT',

  GET_POINTS_REQUEST: 'GET_POINTS_REQUEST',
  IS_LOADING_GET_POINTS_REQUEST: 'IS_LOADING_GET_POINTS_REQUEST',
  IS_LOADING_UPDATE_POINTS_REQUEST: 'IS_LOADING_UPDATE_POINTS_REQUEST',

  GET_POINTS_EARNED_LAST_30_DAYS: 'GET_POINTS_EARNED_LAST_30_DAYS',

  // Store admin
  GET_PRIVILEGE_AND_INVITE_LIST: 'GET_PRIVILEGE_AND_INVITE_LIST',
  PRIVILEGE_AND_INVITE_LIST_IS_PROCESS: 'PRIVILEGE_AND_INVITE_LIST_IS_PROCESS',
  DELETE_INVITATION_SUCCESS: 'DELETE_INVITATION_SUCCESS',
  DELETE_INVITATION_FAIL: 'DELETE_INVITATION_FAIL',
  ADD_INVITATION_SUCCESS: 'ADD_INVITATION_SUCCESS',
  ADD_INVITATION_FAIL: 'ADD_INVITATION_FAIL',
  CHANGE_USER_PRIVILEGE_SUCCESS: 'CHANGE_USER_PRIVILEGE_SUCCESS',
  CHANGE_USER_PRIVILEGE_FAIL: 'CHANGE_USER_PRIVILEGE_FAIL',
  PRIVILEGE_AND_INVITE_LIST_LOADING: 'PRIVILEGE_AND_INVITE_LIST_LOADING',
  ADD_CUSTOM_ROLE_SUCCESS: 'ADD_CUSTOM_ROLE_SUCCESS',
  ADD_CUSTOM_ROLE_FAIL: 'ADD_CUSTOM_ROLE_FAIL',
  EDIT_CUSTOM_ROLE_SUCCESS: 'EDIT_CUSTOM_ROLE_SUCCESS',
  EDIT_CUSTOM_ROLE_FAIL: 'EDIT_CUSTOM_ROLE_FAIL',

  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  GET_MEMBER_PROFILE: 'GET_MEMBER_PROFILE',

  GET_COUNT_ALL_TRANSACTION: 'GET_COUNT_ALL_TRANSACTION',
  GET_ALL_TRANSACTION: 'GET_ALL_TRANSACTION',
  GET_COUNT_ALL_TRANSACTION_FILTER: 'GET_COUNT_ALL_TRANSACTION_FILTER',
  GET_ALL_TRANSACTION_FILTER: 'GET_ALL_TRANSACTION_FILTER',
  SET_MOBILE_FILTER: 'SET_MOBILE_FILTER',
  SET_SETTING: 'SET_SETTING',
  TOKEN_VERIFY: 'TOKEN_VERIFY',
  MEMBER_IS_LOGIN: 'MEMBER_IS_LOGIN',
  MEMBER_IS_LOGOUT: 'MEMBER_IS_LOGOUT',
  GET_SITE_CONFIG: 'GET_SITE_CONFIG',
  GET_REWARD_LIST_START: 'GET_REWARD_LIST_START',
  GET_REWARD_LIST: 'GET_REWARD_LIST',
  GET_ALL_REWARD_LIST: 'GET_ALL_REWARD_LIST',
  GET_REWARD_LIST_MORE: 'GET_REWARD_LIST_MORE',
  GET_REWARD_LIST_END: 'GET_REWARD_LIST_END',
  GET_MEMBER_BALANCE: 'GET_MEMBER_BALANCE',
  RELOAD_PAGE: 'RELOAD_PAGE',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_MEMBER_LIST: 'SET_MEMBER_LIST',
  SET_PREMEMBER_LIST: 'SET_PREMEMBER_LIST',
  SET_LOADING_MEMBER_LIST: 'SET_LOADING_MEMBER_LIST',
  CLEAR_REWARDS: 'CLEAR_REWARDS',

  // COUPON
  GET_DETAILCOUPON: 'GET_DETAILCOUPON',
  GET_UNMOUNT_COUPON: 'GET_UNMOUNT_COUPON',
  GET_LOADING_REDEEM_COUPON: 'GET_LOADING_REDEEM_COUPON',
  ACTIVATED_REDEEM_CODE: 'ACTIVATED_REDEEM_CODE',
  GET_RECEIPT_COUPON: 'GET_RECEIPT_COUPON',
  GET_RECEIPT_COUPON_MORE: 'GET_RECEIPT_COUPON_MORE',
  CREATE_RECEIPT_COUPON: 'CREATE_RECEIPT_COUPON',
  GET_RECEIPT_COUPON_END: 'GET_RECEIPT_COUPON_END',
  DELETE_RECEIPT_COUPON: 'DELETE_RECEIPT_COUPON',
  UPDATE_RECEIPT_COUPON: 'UPDATE_RECEIPT_COUPON',
  GET_GIVEAWAY_COUPON: 'GET_GIVEAWAY_COUPON',
  GET_ALL_GIVEAWAY_COUPON: 'GET_ALL_GIVEAWAY_COUPON',
  GET_GIVEAWAY_COUPON_MORE: 'GET_GIVEAWAY_COUPON_MORE',
  UPDATE_GIVEAWAY_COUPON: 'UPDATE_GIVEAWAY_COUPON',
  GET_GIFTVOUCHER_COUPON: 'GET_GIFTVOUCHER_COUPON',
  GET_GIFTVOUCHER_COUPON_MORE: 'GET_GIFTVOUCHER_COUPON_MORE',
  UPDATE_GIFTVOUCHER_COUPON: 'UPDATE_GIFTVOUCHER_COUPON',

  // REPORT
  GET_REPORT_LOADING: 'GET_REPORT_LOADING',
  CLEAR_REPORT_DEMOGRAPHIC: 'CLEAR_REPORT_DEMOGRAPHIC',
  GET_REPORT_GENDER: 'GET_REPORT_GENDER',
  GET_REPORT_BIRTHDAY: 'GET_REPORT_BIRTHDAY',
  GET_REPORT_BIRTHDAY_MORE: 'GET_REPORT_BIRTHDAY_MORE',
  CLEAR_REPORT_BIRTHDAY: 'CLEAR_REPORT_BIRTHDAY',
  GET_REPORT_POINT: 'GET_REPORT_POINT',
  GET_REPORT_POINT_MORE: 'GET_REPORT_POINT_MORE',
  CLEAR_TRANSACTION_POINT: 'CLEAR_TRANSACTION_POINT',
  GET_REPORT_FREQUENCY_LOADING: 'GET_REPORT_FREQUENCY_LOADING',
  GET_REPORT_FREQUENCY: 'GET_REPORT_FREQUENCY',
  GET_REPORT_RECENCY_LOADING: 'GET_REPORT_RECENCY_LOADING',
  GET_REPORT_RECENCY: 'GET_REPORT_RECENCY',
  GET_REPORT_ADMIN_USAGE: 'GET_REPORT_ADMIN_USAGE',
  SET_REPORT_TRANS_ADMIN: 'SET_REPORT_TRANS_ADMIN',
  GET_REPORT_ADMIN_USAGE_MORE: 'GET_REPORT_ADMIN_USAGE_MORE',
  CLEAR_TRANSACTION_ADMIN: 'CLEAR_TRANSACTION_ADMIN',
  GET_REPORT_POINTS_FLOW: 'GET_REPORT_POINTS_FLOW',
  CLEAR_REPORT_POINTS_FLOW: 'CLEAR_REPORT_POINTS_FLOW',
  GET_REPORT_REWARDS: 'GET_REPORT_REWARDS',
  GET_REPORT_REWARDS_MORE: 'GET_REPORT_REWARDS_MORE',
  CLEAR_REPORT_REWARDS: 'CLEAR_REPORT_REWARDS',
  GET_REPORT_COUPONS: 'GET_REPORT_COUPONS',
  GET_REPORT_COUPONS_MORE: 'GET_REPORT_COUPONS_MORE',
  CLEAR_REPORT_COUPONS: 'CLEAR_REPORT_COUPONS',
  STOP_LOADING: 'STOP_LOADING',
  GET_REPORT_TRANSACTION_SENT: 'GET_REPORT_TRANSACTION_SENT',
  GET_REPORT_TRANSACTION_SENT_MORE: 'GET_REPORT_TRANSACTION_SENT_MORE',
  GET_REPORT_TRANSACTION_ACTIVATED: 'GET_REPORT_TRANSACTION_ACTIVATED',
  GET_REPORT_TRANSACTION_ACTIVATED_MORE:
    'GET_REPORT_TRANSACTION_ACTIVATED_MORE',
  GET_REPORT_RFM_ANALYSIS: 'GET_REPORT_RFM_ANALYSIS',
  GET_REPORT_TRANSACTION_MEMBERCARD: 'GET_REPORT_TRANSACTION_MEMBERCARD',
  GET_REPORT_TRANSACTION_MEMBERCARD_MORE:
    'GET_REPORT_TRANSACTION_MEMBERCARD_MORE',
  CLEAR_REPORT_TRANSACTION_MEMBERCARD: 'CLEAR_REPORT_TRANSACTION_MEMBERCARD',
  GET_REPORT_ADMIN: 'GET_REPORT_ADMIN',
  CLEAR_REPORT_ADMIN: 'CLEAR_REPORT_ADMIN',
  GET_REPORT_PRODUCT_SUMMARY: 'GET_REPORT_PRODUCT_SUMMARY',
  GET_REPORT_PRODUCT_DETAIL: 'GET_REPORT_PRODUCT_DETAIL',
  GET_REPORT_GET_FRIENDS: 'GET_REPORT_GET_FRIENDS',
  CLEAR_REPORT_GET_FRIENDS: 'CLEAR_REPORT_GET_FRIENDS',
  SET_EXPORT_POINT_ALL_TRANS_CANCEL_SOURCE:
    'SET_EXPORT_POINT_ALL_TRANS_CANCEL_SOURCE',
  GET_REPORT_DASHBOARD: 'GET_REPORT_DASHBOARD',
  CLEAR_REPORT_DASHBOARD: 'CLEAR_REPORT_DASHBOARD',
  GET_REPORT_DASHBOARD_REVIEW: 'GET_REPORT_DASHBOARD_REVIEW',
  IS_LOADING_GET_TRANSACTION_SUMMARY: 'IS_LOADING_GET_TRANSACTION_SUMMARY',
  GET_TRANSACTION_SUMMARY_REPORT: 'GET_TRANSACTION_SUMMARY_REPORT',
  IS_LOADING_GET_DATA_TRANSACTION_CHART: 'IS_LOADING_GET_DATA_TRANSACTION_CHART',
  GET_DATA_TRANSACTION_CHART: 'GET_DATA_TRANSACTION_CHART',

  // ECOUPON
  ECOUPON_CLEAR: 'ECOUPON_CLEAR',
  ECOUPON_LOADING: 'ECOUPON_LOADING',
  ECOUPON_FAIL: 'ECOUPON_FAIL',
  ECOUPON_GET: 'ECOUPON_GET',
  ECOUPON_GET_ALL: 'ECOUPON_GET_ALL',
  ECOUPON_GET_MORE: 'ECOUPON_GET_MORE',
  ECOUPON_GET_MEMBER_PROFILE: 'ECOUPON_GET_MEMBER_PROFILE',
  ECOUPON_GET_MEMBER_PROFILE_LOADING: 'ECOUPON_GET_MEMBER_PROFILE_LOADING',
  ECOUPON_GET_MEMBER_PROFILE_SUCCESS: 'ECOUPON_GET_MEMBER_PROFILE_SUCCESS',
  ECOUPON_CLEAR_MEMBER_PROFILE: 'ECOUPON_CLEAR_MEMBER_PROFILE',

  // ECARD
  ECARD_CODE_TYPE_AUTO: 'auto',
  ECARD_CODE_TYPE_IMPORT: 'import',

  // VERIFYBUSINESS
  GET_VERIFYBUSINESS_PROCESS: 'GET_VERIFYBUSINESS_PROCESS',
  GET_VERIFYBUSINESS: 'GET_VERIFYBUSINESS',
  CREATE_VERIFYBUSINESS_PROCESS: 'CREATE_VERIFYBUSINESS_PROCESS',
  VERIFYBUSINESS_PROCESS: 'VERIFYBUSINESS_PROCESS',
  UPDATE_VERIFYBUSINESS: 'UPDATE_VERIFYBUSINESS',
  UPDATE_VERIFYBUSINESS_DETAIL: 'UPDATE_VERIFYBUSINESS_DETAIL',
};

module.exports.TRANSACTIONS = {
  GET_LIST: 'TRANSACTIONS_GET_LIST',
  GET_FILTER_LIST: 'TRANSACTIONS_GET_FILTER_LIST',
  GET_MEMBER_PROFILE: 'TRANSACTIONS_GET_MEMBER_PROFILE',
  GET_MEMBER_BALANCE: 'TRANSACTIONS_GET_MEMBER_BALANCE',
  CLEAR_STATE: 'TRANSACTIONS_CLEAR_STATE',
  CLEAR_SEARCH: 'TRANSACTIONS_CLEAR_PROFILE_SEARCH',
  ACTIVATE_CODE: 'TRANSACTIONS_ACTIVATE_CODE',
  TRANSACTIONS_LOADING: 'TRANSACTIONS_LOADING',
  TRANSACTIONS_LOADING2: 'TRANSACTIONS_LOADING2',
  GET_TRANSACTION_WITH_TRANDID: 'GET_TRANSACTION_WITH_TRANDID',
  CLEAR_TRANSACTION_WITH_TRANDID: 'CLEAR_TRANSACTION_WITH_TRANDID',
  TOGGEL_TRANSACTION_PERSONAL: 'TOGGEL_TRANSACTION_PERSONAL',
  GET_REDEEM_REWARDS: 'GET_REDEEM_REWARDS',
  UPDATE_REDEEM_REWARDS: 'UPDATE_REDEEM_REWARDS',
  SET_EXPORT_POINT_TRANSACTION_CANCEL_SOURCE:
    'SET_EXPORT_POINT_TRANSACTION_CANCEL_SOURCE',
};

module.exports.TRANSFERS = {
  GET_MEMBER_PROFILE: 'TRANSFERS_GET_MEMBER_PROFILE',
  PROFILE_SEARCHING: 'TRANSFERS_SEARCHING',
  CLEAR_MEMBER_PROFILE: 'TRANSFERS_CLEAR_MEMBER_PROFILE',
  TRANSFER_POINT_SUCCESS: 'TRANSFER_POINT_SUCCESS',
  TRANSFER_POINT_UNSUCCESS: 'TRANSFER_POINT_UNSUCCESS',
  TRANSFER_POINT_IS_PROCESS: 'TRANSFER_POINT_IS_PROCESS',
  PRE_REGISTER: 'PRE_REGISTER',
  CLEAR_ERROR: 'CLEAR_ERROR',
};

module.exports.JOBS = {
  GET_CRONJOBS: 'JOBS_GET_CRONJOBS',
  RESET_CRONJOBS: 'JOBS_RESET_CRONJOBS',
  GET_CRONJOBS_DETAIL: 'JOBS_GET_CRONJOBS_DETAIL',
  RESET_CRONJOBS_DETAIL: 'JOBS_RESET_CRONJOBS_DETAIL',
  UPDATE_CRONJOBS_STATUS: 'JOBS_UPDATE_CRONJOBS_STATUS',
  IS_ERROR: 'JOBS_IS_ERROR',
  IS_LOADING: 'JOBS_IS_LOADING',
  IS_PROCESS: 'JOBS_IS_PROCESS',
  IMPORT_CRONJOBS_FILE: 'JOBS_IMPORT_CRONJOBS_FILE',
  INIT_STATE: 'JOBS_INIT_STATE',
  INIT_STATE_JOB_DETAIL: 'INIT_STATE_JOB_DETAIL',
  TOGGLE_TAB_MEMBER: 'TOGGLE_TAB_MEMBER',
};

module.exports.MESSAGE = {
  FETCH_BROADCAST_MESSAGE: 'FETCH_BROADCAST_MESSAGE',
  FETCH_MESSAGE: 'FETCH_MESSAGE',
  READ_MESSAGE: 'READ_MESSAGE',
};

module.exports.SUBSCRIBE = {
  SET_SUBSCRIBE: 'SUBSCRIBE_SET_SUBSCRIBE',
  IS_ERROR: 'SUBSCRIBE_IS_ERROR',
};

module.exports.PACKAGE = {
  PACKAGE_IS_LOADING: 'PACKAGE_IS_LOADING',
  GET_PACKAGES: 'GET_PACKAGES',
  GET_BUSINESS_PACKAGE: 'GET_BUSINESS_PACKAGE',
  GET_BUSINESS_SUBSCRIPTION: 'GET_BUSINESS_SUBSCRIPTION',
  CREATE_BUSINESS_SUBSCRIPTION: 'CREATE_BUSINESS_SUBSCRIPTION',
  CANCLE_BUSINESS_SUBSCRIPTION: 'CANCLE_BUSINESS_SUBSCRIPTION',
  PACKAGE_IS_PROCESS: 'PACKAGE_IS_PROCESS',
};

module.exports.PAYMENT = {
  CLEAR_PATMENT: 'CLEAR_PATMENT',
  SET_PATMENT_LOADING: 'SET_PATMENT_LOADING',
  GET_CUSTOMER_PAYMENT: 'GET_CUSTOMER_PAYMENT',
  GET_CUSTOMER_PAYMENT_CARD: 'GET_CUSTOMER_PAYMENT_CARD',
};

module.exports.CREDENTIAL = {
  IS_LOADING: 'IS_LOADING',
  GET_CREDENTIALS: 'GET_CREDENTIALS',
  CREATE_CREDENTIAL: 'CREATE_CREDENTIAL',
  REVOKE_CREDENTIAL: 'REVOKE_CREDENTIAL',
  UPDATE_CREDENTIAL: 'UPDATE_CREDENTIAL',
};

module.exports.USAGE = {
  USAGE_RECORD_LOADING: 'USAGE_RECORD_LOADING',
  GET_USAGE_RECORD: 'GET_USAGE_RECORD',
  INCREASE_USAGE_RECORD: 'INCREASE_USAGE_RECORD',
  LOADING_IS_OVER_TRANS: 'LOADING_IS_OVER_TRANS',
  GET_IS_OVER_TRANS: 'GET_IS_OVER_TRANS',
  GET_FREE_USAGE_OVER_LIMIT: 'GET_FREE_USAGE_OVER_LIMIT',
};

module.exports.MEMBER = {
  GET_MEMBER_PROFILE: 'GET_MEMBER_PROFILE',
  GET_MEMBER_TOTAL: 'GET_MEMBER_TOTAL',
  MEMBER_LOGIN: 'MEMBER_LOGIN',
  MEMBER_LOGOUT: 'MEMBER_LOGOUT',
  SET_MEMBER_REGISTER: 'SET_MEMBER_REGISTER',
  SET_MEMBER_REF_CODE: 'SET_MEMBER_REF_CODE',

  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
  SET_MEMBER_BADGE_CODE: 'SET_MEMBER_BADGE_CODE',
  SET_MEMBER_PROFILE: 'SET_MEMBER_PROFILE',
  SET_MEMBER_PROFILE_INFO: 'SET_MEMBER_PROFILE_INFO',
  UPDATE_MEMBER_PROFILE: 'UPDATE_MEMBER_PROFILE',
  SET_MEMBER_MOBILE_NUMBER: 'SET_MEMBER_MOBILE_NUMBER',

  SET_USER_PERMISSION: 'SET_USER_PERMISSION',

  IS_PROCESS: 'MEMBER_IS_PROCESS',
  PROFILE_REGISTER: 'MEMBER_PROFILE_REGISTER',

  START_PROCESS: 'START_PROCESS',
  UPDATE_MEMBER_PROFILE_2: 'UPDATE_MEMBER_PROFILE_2',
  UPDATE_PROFILE_AVATAR: 'UPDATE_PROFILE_AVATAR',

  GET_MEMBER_COUPON: 'GET_MEMBER_COUPON',
  CLEAR_MEMBER_COUPON: 'CLEAR_MEMBER_COUPON',
  RESOTRE_MEMBER_INFOMATION: 'RESOTRE_MEMBER_INFOMATION',

  GET_PERSONAL_MEMBER_CARD_INFO: 'GET_PERSONAL_MEMBER_CARD_INFO',
  CREATE_PERSONAL_MEMBER_CARD_INFO: 'CREATE_PERSONAL_MEMBER_CARD_INFO',

  START_UPDATE_LABEL_MEMBER: 'START_UPDATE_LABEL_MEMBER',
  UPDATE_LABEL_MEMBER: 'UPDATE_LABEL_MEMBER',
  TOGGLE_EDIT_ALIAS_NAME_MODAL: 'TOGGLE_EDIT_ALIAS_NAME_MODAL',
  UPDATE_ALIAS_NAME: 'UPDATE_ALIAS_NAME',
  RESET_FILTER_MEMBER: 'RESET_FILTER_MEMBER',
};

module.exports.VERIFYPHONE = {
  IS_PROCESS: 'VERIFY_IS_PROCESS',
  PHONE_REQUEST_OTP: 'VERIFY_PHONE_REQUEST_OTP',
  PHONE_REQUEST_OTP_ERROR: 'PHONE_REQUEST_OTP_ERROR',
  VERIFY_OTP_ERROR: 'VERIFY_OTP_ERROR',
  SET_RESEND_OTP_COOLDOWN: 'VERIFY_SET_RESEND_OTP_COOLDOWN',
  VERIFY_OTP: 'VERIFY_OTP',
};

module.exports.VERIFY_EMAIL_OTP = {
  VERIFY_IS_PROCESS: 'VERIFY_EMAIL_OTP_IS_PROCESS',
  SEND_EMAIL_IS_PROCESS: 'SEND_EMAIL_IS_PROCESS',
  SEND_EMAIL_OTP_SUCCESS: 'SEND_EMAIL_OTP_SUCCESS',
  SEND_EMAIL_OTP_ERROR: 'SEND_EMAIL_OTP_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  UPDATE_VERIFY_EMAIL_OTP_SUCCESS: 'UPDATE_VERIFY_EMAIL_OTP_SUCCESS',
};

module.exports.TUTORIAL = {
  TOGGLE_OPEN_TUTORIAL: 'TOGGLE_OPEN_TUTORIAL',
};

module.exports.MASTER_DATA = {
  GET_ARCHIVE_REASONS: 'GET_ARCHIVE_REASONS',
};

module.exports.NEWSFEED = {
  GET_BUSINESS_NEWSFEED: 'GET_BUSINESS_NEWSFEED',
  GET_BUSINESS_NEXT_NEWSFEED: 'GET_BUSINESS_NEXT_NEWSFEED',
  CLEAR_BUSINESS_NEWSFEED: 'CLEAR_BUSINESS_NEWSFEED',
};

module.exports.SETTINGPOINTS = {
  PROCESS_POINTS_SETTING_START: 'PROCESS_POINTS_SETTING_START',
  GET_POINTS_SETTING: 'GET_POINTS_SETTING',
  UPDATE_POINTS_SETTING: 'UPDATE_POINTS_SETTING',
  ERROR_POINTS_SETTING: 'ERROR_POINTS_SETTING',
};

module.exports.BROADCAST = {
  GET_BROADCAST_INFO: 'GET_BROADCAST_INFO',
  GET_BROADCAST_INFO_MORE: 'GET_BROADCAST_INFO_MORE',
  CREATE_BROADCAST_INFO: 'CREATE_BROADCAST_INFO',
  UPDATE_BROADCAST_INFO: 'UPDATE_BROADCAST_INFO',
  CLEAR_BROADCAST: 'CLEAR_BROADCAST',

  GET_BROADCAST_PROMOTION: 'GET_BROADCAST_PROMOTION',
  GET_BROADCAST_PROMOTION_MORE: 'GET_BROADCAST_PROMOTION_MORE',
  CREATE_BROADCAST_PROMOTION: 'CREATE_BROADCAST_PROMOTION',
  UPDATE_BROADCAST_PROMOTION: 'UPDATE_BROADCAST_PROMOTION',
  RELOAD_BROADCAST_PROMOTION: 'RELOAD_BROADCAST_PROMOTION',

  INIT_BROADCAST_TARGET: 'INIT_BROADCAST_TARGET',
  GET_TARGET_MEMBER_TOTAL: 'GET_TARGET_MEMBER_TOTAL',
  GET_TARGET_RFM: 'GET_RFM_TOTAL',
  GET_TARGET_RFM_TOTAL: 'GET_TARGET_RFM_TOTAL',
  TARGET_LOADING: 'TARGET_LOADING',
  INIT_BROADCAST_OPTION: 'INIT_BROADCAST_OPTION',
  SET_BROADCAST_OPTION: 'SET_BROADCAST_OPTION',
  UPDATE_TARGET_VALUE: 'UPDATE_TARGET_VALUE',
};

module.exports.PRODUCT = {
  IS_LOADING_GET_CATEGORY: 'IS_LOADING_GET_CATEGORY',
  IS_LOADING_UPDATE_CATEGORY: 'IS_LOADING_UPDATE_CATEGORY',
  GET_CATEGORY_PRODUCT: 'GET_CATEGORY_PRODUCT',
  CREATE_CATEGORY_PRODUCT: 'CREATE_CATEGORY_PRODUCT',
  UPDATE_CATEGORY_PRODUCT: 'UPDATE_CATEGORY_PRODUCT',
  DELETE_CATEGORY_PRODUCT: 'DELETE_CATEGORY_PRODUCT',
  IS_LOADING_GET_PRODUCT: 'IS_LOADING_GET_PRODUCT',
  IS_LOADING_UPDATE_PRODUCT: 'IS_LOADING_UPDATE_PRODUCT',
  GET_PRODUCT: 'GET_PRODUCT',
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  IMPORT_PRODUCT: 'IMPORT_PRODUCT',
  MOVE_ALL_PRODUCT_CATEGORY: 'MOVE_ALL_PRODUCT_CATEGORY',
  SET_HAS_PRODUCT: 'SET_HAS_PRODUCT',
}

module.exports.CONDITION = {
  PROCESS_POINTS_CONDITION: 'PROCESS_POINTS_CONDITION',
  GET_POINTS_CONDITION: 'GET_POINTS_CONDITION',
  OPEN_POINTS_CONDITION: 'OPEN_POINTS_CONDITION',
  ERROR_POINTS_CONDITION: 'ERROR_POINTS_CONDITION',
  UPDATE_POINTS_CONDITION: 'UPDATE_POINTS_CONDITION',
};

module.exports.MANAGER = {
  GET_BUSINESS_PACKAGE: 'MANAGER_GET_BUSINESS_PACKAGE',
  GET_PREPAID_DATA: 'MANAGER_GET_PREPAID_DATA',
  GET_PAYMENT_DATA: 'MANAGER_GET_PAYMENT_DATA',
  GET_CREDIT_BALANCE_HISTORY: 'MANAGER_GET_CREDIT_BALANCE_HISTORY',
  GET_SETTINGS: 'MANAGER_GET_SETTINGS',
  CREATE_SUBSCRIPTION: 'MANAGER_CREATE_SUBSCRIPTION',
  SET_TRIAL_PACKAGE: 'MANAGER_SET_TRIAL_PACKAGE',
  SET_CREDIT_BALANCE: 'MANAGER_SET_CREDIT_BALANCE',
  SET_PREPAID: 'MANAGER_SET_PREPAID',
  SET_PREMIUM_ID: 'MANAGER_SET_PREMIUM_ID',
  SET_SENDER_NAME: 'MANAGER_SET_SENDER_NAME',
  CANCEL_TRIAL_PACKAGE: 'MANAGER_CANCEL_TRIAL_PACKAGE',
  LOADING_API: 'MANAGER_LOADING_API',
  SET_ERROR: 'MANAGER_SET_ERROR',
  SET_PROCESSING_STATUS: 'MANAGER_SET_PROCESSING_STATUS',
  UPDATE_BUSINESS_NAME: 'MANAGER_UPDATE_BUSINESS_NAME',
  CHARGE_INVOICE: 'CHARGE_INVOICE',
  RESET_EXPIRY_POINT: 'MANAGER_RESET_EXPIRY_POINT',
  PROCESS_STATUS_WORKING: 'MANAGER_PROCESS_STATUS_WORKING',
  PROCESS_STATUS_SUCCEED: 'MANAGER_PROCESS_STATUS_SUCCEED',
  PROCESS_STATUS_ERROR: 'MANAGER_PROCESS_STATUS_ERROR',
  GET_COUPON_INFO: 'GET_COUPON_INFO',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_MEMBER_PROFILE: 'SET_MEMBER_PROFILE',
  CLEAR_MEMBER_PROFILE: 'CLEAR_MEMBER_PROFILE',
};

module.exports.PERMISSION = {
  USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAIL: 'USER_LOGIN_FAIL',
  USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
  USER_UNREGISTERED: 'USER_UNREGISTERED',
  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_FAIL: 'CREATE_USER_FAIL',
  GET_SOCIAL_CREDENTIAL: 'GET_SOCIAL_CREDENTIAL',
  EMAIL_ALREADY_VERIFIED: 'EMAIL_ALREADY_VERIFIED',

  SET_REDIRECT_ROUTE: 'SET_REDIRECT_ROUTE',
  USER_NOT_AUTH: 'USER_NOT_AUTH',
  REMOVE_SOCIAL_CREDENTAIL: 'REMOVE_SOCIAL_CREDENTAIL',
  REMOVE_LOGIN_ERROR: 'REMOVE_LOGIN_ERROR',
  UPDATE_AVATAR_USER: 'UPDATE_AVATAR_USER',
  UPDATE_PROFILE_USER: 'UPDATE_PROFILE_USER',
  UPDATE_USER_LOADING: 'UPDATE_USER_LOADING',
  SENT_EMAIL_RESETPASSWORD: 'SENT_EMAIL_RESETPASSWORD',
  SENT_EMAIL_RESETPASSWORD_LOADING: 'SENT_EMAIL_RESETPASSWORD_LOADING',
  PERMISSION_IS_LOADING: 'PERMISSION_IS_LOADING',
  PERMISSION_IS_LOADING_SUCCESS: 'PERMISSION_IS_LOADING_SUCCESS',
  CLEAR_ERROR: 'PERMISSION_CLEAR_ERROR',
  GET_PROFILE_ACCOUNT: 'PERMISSION_GET_PROFILE_ACCOUNT',
  SET_TAB_UUID: 'PERMISSION_SET_TAB_UUID',
};

module.exports.AUTOMATION = {
  AUTOMATION_MEMBER_CARD_TRNASACTIONS: 'AUTOMATION_MEMBER_CARD_TRNASACTIONS',
  AUTOMATION_ECARD_TRNASACTIONS: 'AUTOMATION_ECARD_TRNASACTIONS',
  AUTOMATION_ECARD_CAMPAIGN_LIST: 'AUTOMATION_ECARD_CAMPAIGN_LIST',
  AUTOMATION_SET_TRIGGER: 'AUTOMATION_SET_TRIGGER',
};

module.exports.TOTP = {
  VERIFY_TOKEN: 'VERIFY_TOKEN',
  SET_VERIFY_TOKEN: 'SET_VERIFY_TOKEN',
};

module.exports.RFM = {
  GET_RFM_GROUP_DATA: 'GET_RFM_GROUP_DATA',
  GET_GENDER: 'GET_GENDER',
  GET_DATA_RFM_FOR_TABLE: 'GET_DATA_RFM_FOR_TABLE',
  GET_DATA_RFM_FOR_EXPORT: 'GET_DATA_RFM_FOR_EXPORT',
  SET_IS_LOADING_DATA: 'SET_IS_LOADING_DATA',
  RESET_GENDER_COUNT: 'RESET_GENDER_COUNT',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  LOADING_DATA_MEMBER: 'LOADING_DATA_MEMBER',
  RESET_DATA_RFM_FOR_TABLE: 'RESET_DATA_RFM_FOR_TABLE',
  GET_TOTAL_RFM_GROUP: 'GET_TOTAL_RFM_GROUP',
  RESET_DATA_RFM: 'RESET_DATA_RFM',
};

module.exports.CUSTOM_FORM = {
  GET_CUSTOM_FORM_TITLE: 'GET_CUSTOM_FORM_TITLE',
  GET_CUSTOM_FORM_TITLE_ALL: 'GET_CUSTOM_FORM_TITLE_ALL',
  IS_LOADING: 'IS_LOADING',
  UPDATE_CUSTOM_FORM_TITLE: 'UPDATE_CUSTOM_FORM_TITLE',
  GET_CUSTOM_FORM_VALUE: 'GET_CUSTOM_FORM_VALUE',
  IS_LOADING_UPDATE: 'IS_LOADING_UPDATE',
  IS_LOADING_CUSTOM_FORM_VALUE: 'IS_LOADING_CUSTOM_FORM_VALUE',
  CLEAR_CUSTOM_FORM_TITLE: 'CLEAR_CUSTOM_FORM_TITLE',
};
